import HlsLivePlayer from 'xgplayer-hls-live';
import HlsVodPlayer from 'xgplayer-hls-vod';
import Player from 'xgplayer';

export default class HlsPlayer {
  constructor (config) {
    if (config.isLive) {
      return new HlsLivePlayer(config)
    } else {
      return new HlsVodPlayer(config)
    }
  }

  static isSupported () {
    return window.MediaSource &&
      window.MediaSource.isTypeSupported('video/mp4; codecs="avc1.42E01E,mp4a.40.2"');
  }

  static install (name, plugin) {
    return Player.install(name, plugin)
  }
}
