var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import Player from 'xgplayer';
import HlsLiveMsePlayer from './mse';
import HlsLiveMobilePlayer from './mobile';
import { EVENTS } from 'xgplayer-helper-utils';
// import 'xgplayer-mobilevideo'

var HlsLivePlayer = function () {
  function HlsLivePlayer(config) {
    _classCallCheck(this, HlsLivePlayer);

    if (HlsLiveMobilePlayer.isSupported() && config.useWASM) {
      return new HlsLiveMobilePlayer(config);
    }if (HlsLiveMsePlayer.isSupported()) {
      return new HlsLiveMsePlayer(config);
    } else {
      return new Player(config);
    }
  }

  _createClass(HlsLivePlayer, null, [{
    key: 'install',
    value: function install(name, plugin) {
      return Player.install(name, plugin);
    }
  }]);

  return HlsLivePlayer;
}();

export default HlsLivePlayer;


HlsLivePlayer.EVENTS = EVENTS;