var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * @typedef {Object} TsFragProps
 * @property {number} start
 * @property {number} duration
 * @property {string} url
 * @property {boolean} discontinue
 * @property {number} id
 * @property {number} cc
 */
var TsFrag = function () {
  _createClass(TsFrag, null, [{
    key: 'getDefault',

    /**
     *
     * @return TsFrag
     */
    value: function getDefault() {
      return {
        id: -1,
        url: '',
        start: -1,
        duration: -1,
        discontinue: false,
        cc: -1
      };
    }
    /**
     *
     * @param {TsFragProps} props
     */

  }]);

  function TsFrag(props) {
    _classCallCheck(this, TsFrag);

    var merged = Object.assign({}, TsFrag.getDefault(), props);

    this.id = merged.id;

    this.url = merged.url;

    this.start = merged.start;

    this.duration = merged.duration;

    this.discontinue = merged.discontinue;

    this.cc = merged.cc;
  }

  return TsFrag;
}();

export default TsFrag;