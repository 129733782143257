var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import EventEmitter from 'eventemitter3';
import { logger } from 'xgplayer-helper-utils';
import { XGDataView, AudioTrackMeta, VideoTrackMeta, AudioSample, VideoSample } from 'xgplayer-helper-models';
import { ADTS, avc, hevc } from 'xgplayer-helper-codec';

/**
 * @typedef {import('xgplayer-helper-models').TsFrag } TsFrag
 */
/**
 * @typedef {import('xgplayer-helper-models').Buffer } Buffer
 */
var NalUnit = avc.NalUnit;
var NalUnitHEVC = hevc.NalUnitHEVC;

var StreamType = {
  0x01: ['video', 'MPEG-1'],
  0x02: ['video', 'MPEG-2'],
  0x1b: ['video', 'AVC.H264'],
  0x24: ['video', 'HVC.H265'],
  0xea: ['video', 'VC-1'],
  0x03: ['audio', 'MPEG-1'],
  0x04: ['audio', 'MPEG-2'],
  0x0f: ['audio', 'MPEG-2.AAC'],
  0x11: ['audio', 'MPEG-4.AAC'],
  0x80: ['audio', 'LPCM'],
  0x81: ['audio', 'AC3'],
  0x06: ['audio', 'AC3'],
  0x82: ['audio', 'DTS'],
  0x83: ['audio', 'Dolby TrueHD'],
  0x84: ['audio', 'AC3-Plus'],
  0x85: ['audio', 'DTS-HD'],
  0x86: ['audio', 'DTS-MA'],
  0xa1: ['audio', 'AC3-Plus-SEC'],
  0xa2: ['audio', 'DTS-HD-SEC']
};

var TsDemuxer = function (_EventEmitter) {
  _inherits(TsDemuxer, _EventEmitter);

  _createClass(TsDemuxer, null, [{
    key: 'EVENTS',
    get: function get() {
      return {
        DEMUX_COMPLETE: 'DEMUX_COMPLETE',
        METADATA_PARSED: 'METADATA_PARSED',
        VIDEO_SAMPLE_PARSED: 'VIDEO_SAMPLE_PARSED',
        AUDIO_SAMPLE_PARSED: 'AUDIO_SAMPLES_PARSED',
        SEI_PARSED: 'SEI_PARSED'
      };
    }

    /**
     * @param {any} videoTrack
     * @param {any} audioTrack
     */

  }]);

  function TsDemuxer(_ref) {
    var videoTrack = _ref.videoTrack,
        audioTrack = _ref.audioTrack;

    _classCallCheck(this, TsDemuxer);

    var _this = _possibleConstructorReturn(this, (TsDemuxer.__proto__ || Object.getPrototypeOf(TsDemuxer)).call(this));

    _this.TAG = 'TsDemuxer';
    _this.demuxing = false;
    _this.videoTrack = videoTrack;
    _this.audioTrack = audioTrack;
    _this.pat = [];
    _this.pmt = [];
    _this._hasVideoMeta = false;
    _this._hasAudioMeta = false;
    _this.gopId = 0;
    return _this;
  }

  /**
   *
   * @param {TsFrag} frag ts fragment parsed from m3u8
   * @param {Buffer} buffer u8a-buffer
   * @param {boolean} isVod is a vod m3u8
   */


  _createClass(TsDemuxer, [{
    key: 'demux',
    value: function demux(frag, buffer, isVod) {
      if (frag) {
        logger.log(this.TAG, 'do demux: id=' + frag.id + ',demuxing=' + this.demuxing);
      }
      if (this.demuxing) {
        return;
      }

      var frags = { pat: [], pmt: [] };
      var peses = {};
      while (buffer.length >= 188) {
        if (buffer.length >= 1 && buffer.array[0][buffer.offset] !== 71) {
          throw new Error('Untrust sync code: ' + buffer.array[0][buffer.offset] + ', try to recover;');
        }
        while (buffer.length >= 1 && buffer.array[0][buffer.offset] !== 71) {
          buffer.shift(1);
        }
        if (buffer.length < 188) {
          continue;
        }
        var buf = buffer.shift(188);
        // console.log(buf);
        var tsStream = new XGDataView(buf.buffer);
        var ts = {};
        TsDemuxer.read(tsStream, ts, frags);
        var pes = peses[ts.header.pid];
        if (ts.pes) {
          ts.pes.codec = ts.header.codec;
          ts.pes.streamType = ts.header.streamType;
          if (!peses[ts.header.pid]) {
            peses[ts.header.pid] = [];
          }
          peses[ts.header.pid].push(ts.pes);
          ts.pes.ES.buffer = [ts.pes.ES.buffer];
        } else if (pes) {
          pes[pes.length - 1].ES.buffer.push(ts.payload.stream);
        }
      }

      var AudioOptions = Object.assign({}, frag);
      var VideoOptions = Object.assign({}, frag);

      var noAudio = isVod && this._hasVideoMeta && !this._hasAudioMeta;
      var noVideo = isVod && this._hasAudioMeta && !this._hasVideoMeta;

      // Get Frames data
      for (var i = 0; i < Object.keys(peses).length; i++) {
        var epeses = peses[Object.keys(peses)[i]];
        for (var j = 0; j < epeses.length; j++) {
          var cPes = epeses[j];
          cPes.id = Object.keys(peses)[i];

          // 1. !noAudio 首片无音频,后续分片当无音频处理
          // 2. cPes.streamType === 0x0f || cPes.streamType === 0x11 只处理aac,其他音频格式当无音频
          var isAAC = cPes.streamType === 0x0f || cPes.streamType === 0x11;
          if (cPes.type === 'audio' && !noAudio && isAAC) {
            cPes.ES.buffer = TsDemuxer.mergeAudioES(cPes.ES.buffer);
            this.pushAudioSample(cPes, AudioOptions);
            AudioOptions = {};
          } else if (cPes.type === 'video' && !noVideo) {
            cPes.ES.buffer = TsDemuxer.mergeVideoES(cPes.ES.buffer);
            if (cPes.codec === 'HVC.H265') {
              this.pushVideoSampleHEVC(epeses[j], VideoOptions);
            } else {
              this.pushVideoSample(epeses[j], VideoOptions);
            }
            VideoOptions = { spsFound: !!VideoOptions.spsFound };
          }
        }
      }
    }
  }, {
    key: 'pushAudioSample',
    value: function pushAudioSample(pes, options) {
      var meta = new AudioTrackMeta({
        audioSampleRate: pes.ES.frequence,
        sampleRate: pes.ES.frequence,
        channelCount: pes.ES.channel,
        codec: 'mp4a.40.' + pes.ES.audioObjectType,
        originCodec: 'mp4a.40.' + pes.ES.originAudioObjectType,
        originObjectType: pes.ES.originAudioObjectType,
        config: pes.ES.audioConfig,
        id: 2,
        sampleRateIndex: pes.ES.frequencyIndex
      });
      meta.refSampleDuration = Math.floor(1024 / meta.audioSampleRate * meta.timescale);

      var metaEqual = TsDemuxer.compareMeta(this.audioTrack.meta, meta, true);

      if (!this._hasAudioMeta || !metaEqual) {
        this._hasAudioMeta = true;
        if (options) {
          options.meta = Object.assign({}, meta);
        } else {
          options = {
            meta: Object.assign({}, meta)
          };
        }
        this.emit(TsDemuxer.EVENTS.METADATA_PARSED, 'audio', meta);
      }

      var frameIndex = 0;
      pes.ES.buffer.skip(pes.pesHeaderLength + 9);
      var streamChanged = false;
      while (pes.ES.buffer.position < pes.ES.buffer.length) {
        if (ADTS.isHeader(new Uint8Array(pes.ES.buffer.buffer), pes.ES.buffer.position) && pes.ES.buffer.position + 5 < pes.ES.buffer.length) {
          var frame = ADTS.appendFrame(this.audioTrack.meta.sampleRate, new Uint8Array(pes.ES.buffer.buffer), pes.ES.buffer.position, pes.pts, frameIndex);
          if (frame && frame.sample) {
            // logger.log(`${Math.round(frame.sample.pts)} : AAC`);
            pes.ES.buffer.skip(frame.length);
            var sample = new AudioSample({
              dts: frame.sample.dts,
              pts: frame.sample.pts,
              data: frame.sample.unit,
              options: streamChanged ? {} : options
            });
            if (options.meta) {
              streamChanged = true;
            }
            sample.dts = sample.pts = Math.ceil(sample.pts / 90);
            this.emit(TsDemuxer.EVENTS.AUDIO_SAMPLE_PARSED, sample);
            frameIndex++;
          } else {
            // logger.log('Unable to parse AAC frame');
            break;
          }
        } else {
          // nothing found, keep looking
          pes.ES.buffer.skip(1);
        }
      }
    }
  }, {
    key: 'pushVideoSample',
    value: function pushVideoSample(pes, options) {
      var _this2 = this;

      var nals = NalUnit.getNalunits(pes.ES.buffer);
      var meta = new VideoTrackMeta();
      var spsFound = options.spsFound; // 保存当前分片内是否解析过sps
      var sampleLength = 0;
      var sps = false;
      var pps = false;
      var seiList = [];
      for (var i = 0; i < nals.length; i++) {
        var nal = nals[i];
        if (nal.type < 9 && !nal.sei) {
          sampleLength += 4 + nal.body.byteLength;
        }
        if (nal.sps) {
          sps = nal;
          meta.sps = nal.body;
          meta.chromaFormat = sps.sps.chroma_format;
          meta.codec = 'avc1.';
          for (var j = 1; j < 4; j++) {
            var h = sps.body[j].toString(16);
            if (h.length < 2) {
              h = '0' + h;
            }
            meta.codec += h;
          }
          meta.codecHeight = sps.sps.codec_size.height;
          meta.codecWidth = sps.sps.codec_size.width;
          meta.frameRate = sps.sps.frame_rate;
          meta.id = 1;
          meta.level = sps.sps.level_string;
          meta.presentHeight = sps.sps.present_size.height;
          meta.presentWidth = sps.sps.present_size.width;
          meta.profile = sps.sps.profile_string;
          meta.refSampleDuration = Math.floor(meta.timescale * (sps.sps.frame_rate.fps_den / sps.sps.frame_rate.fps_num));
          meta.sarRatio = sps.sps.sar_ratio ? sps.sps.sar_ratio : sps.sps.par_ratio;
        } else if (nal.pps) {
          meta.pps = nal.body;
          pps = nal;
        } else if (nal.sei) {
          seiList.push(nal.sei);
        }
      }

      if (sps && pps) {
        meta.avcc = NalUnit.getAvcc(sps.body, pps.body);
        var metaEqual = TsDemuxer.compareMeta(this.videoTrack.meta, meta, true);
        if (!this._hasVideoMeta || !metaEqual) {
          if (options) {
            options.meta = Object.assign({}, meta);
          } else {
            options = {
              meta: Object.assign({}, meta)
            };
          }
          if (!spsFound) {
            this.emit(TsDemuxer.EVENTS.METADATA_PARSED, 'video', meta);
          }
          options.spsFound = true;
          this._hasVideoMeta = true;
        }
      }

      var data = new Uint8Array(sampleLength);
      var offset = 0;
      var isKeyframe = false;
      for (var _i = 0; _i < nals.length; _i++) {
        var _nal = nals[_i];
        if (_nal.type && _nal.type >= 9) {
          continue;
        }
        var length = _nal.body.byteLength;
        if (_nal.idr) {
          isKeyframe = true;
        }
        if (!_nal.sei) {
          data.set(new Uint8Array([length >>> 24 & 0xff, length >>> 16 & 0xff, length >>> 8 & 0xff, length & 0xff]), offset);
          offset += 4;
          data.set(_nal.body, offset);
          offset += length;
        }
      }
      var dts = parseInt(pes.dts / 90);
      var pts = parseInt(pes.pts / 90);

      if (seiList.length) {
        seiList.forEach(function (sei) {
          sei.dts = dts;
          _this2.emit(TsDemuxer.EVENTS.SEI_PARSED, sei);
        });
      }
      var sample = new VideoSample({
        dts: dts,
        pts: pts,
        cts: pts - dts,
        originDts: pes.dts,
        purePts: pes.purePts,
        isKeyframe: isKeyframe,
        data: data,
        nals: nals,
        options: options,
        firstInGop: isKeyframe,
        gopId: isKeyframe ? ++this.gopId : this.gopId
      });
      this.emit(TsDemuxer.EVENTS.VIDEO_SAMPLE_PARSED, sample);
    }
  }, {
    key: 'pushVideoSampleHEVC',
    value: function pushVideoSampleHEVC(pes, options) {
      var _this3 = this;

      var nals = NalUnitHEVC.getNalunits(pes.ES.buffer);
      nals = nals.filter(function (x) {
        return x.body && x.body.length;
      });
      var meta = new VideoTrackMeta();
      meta.streamType = 0x24;

      var sampleLength = 0;
      var vps = false;
      var sps = false;
      var pps = false;
      var seiList = [];
      var hasVPS = false;
      var hasSPS = false;
      var hasPPS = false;
      var firstInGop = false;
      for (var i = 0; i < nals.length; i++) {
        var nal = nals[i];

        if (nal.vps) {
          if (hasVPS) {
            continue;
          } else {
            hasVPS = true;
          }
        } else if (nal.sps) {
          if (hasSPS) {
            continue;
          } else {
            hasSPS = true;
          }
        } else if (nal.pps) {
          if (hasPPS) {
            continue;
          } else {
            hasPPS = true;
          }
        } else if (nal.key) {
          if (nal.type === 20 || nal.type === 19) {
            firstInGop = true;
          }
        } else if (nal.type === 0) {
          // if (!hasKeyframe) {
          //   continue;
          // }
        } else if (nal.type === 35) {
          continue;
        }
        if (nal.sps) {
          sps = nal;
          meta.sps = nal.body;

          meta.presentWidth = sps.sps.width;
          meta.presentHeight = sps.sps.height;
          meta.general_profile_space = sps.sps.general_profile_space;
          meta.general_tier_flag = sps.sps.general_tier_flag;
          meta.general_profile_idc = sps.sps.general_profile_idc;
          meta.general_level_idc = sps.sps.general_level_idc;
          // meta.duration = this._duration;
          meta.codec = 'hev1.1.6.L93.B0';
          meta.chromaFormatIdc = sps.sps.chromaFormatIdc;
          meta.bitDepthLumaMinus8 = sps.sps.bitDepthLumaMinus8;
          meta.bitDepthChromaMinus8 = sps.sps.bitDepthChromaMinus8;
        } else if (nal.pps) {
          meta.pps = nal.body;
          pps = nal;
        } else if (nal.vps) {
          meta.vps = nal.body;
          vps = nal;
        } else if (nal.sei) {
          seiList.push(nal.sei);
        }
        if (nal.type <= 40) {
          sampleLength += 4 + nal.body.byteLength;
        }
      }

      if (sps && pps && vps) {
        // meta.avcc = NalUnitHEVC.getAvcc(sps.body, pps.body);
        var metaEqual = TsDemuxer.compareMeta(this.videoTrack.meta, meta, true);
        if (!this._hasVideoMeta || !metaEqual) {
          if (options) {
            options.meta = Object.assign({}, meta);
          } else {
            options = {
              meta: Object.assign({}, meta)
            };
          }
          meta.streamType = 0x24;
          this.videoTrack.meta = meta;
          this._hasVideoMeta = true;
          this.emit(TsDemuxer.EVENTS.METADATA_PARSED, 'video', meta);
        }
      }

      var data = new Uint8Array(sampleLength);
      var offset = 0;
      var isKeyframe = false;
      hasVPS = false;
      hasSPS = false;
      hasPPS = false;
      for (var _i2 = 0; _i2 < nals.length; _i2++) {
        var _nal2 = nals[_i2];
        if (_nal2.type && _nal2.type > 40) {
          continue;
        }

        if (_nal2.vps) {
          if (hasVPS) {
            continue;
          } else {
            hasVPS = true;
          }
        } else if (_nal2.sps) {
          if (hasSPS) {
            continue;
          } else {
            hasSPS = true;
          }
        } else if (_nal2.pps) {
          if (hasPPS) {
            continue;
          } else {
            hasPPS = true;
          }
        } else if (_nal2.key) {} else if (_nal2.type === 0) {
          // if (!hasKeyframe) {
          //   continue;
          // }
        } else if (_nal2.type === 35) {
          continue;
        }
        var length = _nal2.body.byteLength;
        if (_nal2.key) {
          isKeyframe = true;
        }
        // if (!nal.vps && !nal.pps && !nal.sps) {
        data.set(new Uint8Array([length >>> 24 & 0xff, length >>> 16 & 0xff, length >>> 8 & 0xff, length & 0xff]), offset);
        offset += 4;
        data.set(_nal2.body, offset);
        offset += length;
        // }
      }
      var dts = parseInt(pes.dts / 90);
      var pts = parseInt(pes.pts / 90);

      if (seiList) {
        seiList.forEach(function (sei) {
          sei.dts = dts;
          _this3.emit(TsDemuxer.EVENTS.SEI_PARSED, sei);
        });
      }

      var sample = new VideoSample({
        dts: dts,
        pts: pts,
        cts: pts - dts,
        originDts: pes.dts,
        purePts: pes.purePts,
        isKeyframe: isKeyframe,
        data: data,
        nals: nals,
        options: options,
        firstInGop: firstInGop,
        gopId: firstInGop ? ++this.gopId : this.gopId
      });
      this.emit(TsDemuxer.EVENTS.VIDEO_SAMPLE_PARSED, sample);
    }
  }, {
    key: 'destroy',
    value: function destroy() {
      this.removeAllListeners();
      this.configs = {};
      this.demuxing = false;
      this.pat = [];
      this.pmt = [];
      this._hasVideoMeta = false;
      this._hasAudioMeta = false;
    }
  }], [{
    key: 'compareArray',
    value: function compareArray(a, b, type) {
      var al = 0;
      var bl = 0;
      if (type === 'Uint8Array') {
        al = a.byteLength;
        bl = b.byteLength;
      } else if (type === 'Array') {
        al = a.length;
        bl = b.length;
      }
      if (al !== bl) {
        return false;
      }

      for (var i = 0; i < al; i++) {
        if (a[i] !== b[i]) {
          return false;
        }
      }
      return true;
    }
  }, {
    key: 'compareMeta',
    value: function compareMeta(a, b, ignoreDuration) {
      if (!a || !b) {
        return false;
      }

      for (var i = 0, k = Object.keys(a).length; i < k; i++) {
        var itema = a[Object.keys(a)[i]];
        var itemb = b[Object.keys(a)[i]];
        if (!itema && !itemb) {
          return true;
        }

        if ((itema === undefined || itema === null) && itemb || itema && itemb === undefined) {
          return false;
        }

        if ((typeof itema === 'undefined' ? 'undefined' : _typeof(itema)) !== 'object') {
          if (ignoreDuration && Object.keys(a)[i] !== 'duration' && Object.keys(a)[i] !== 'refSampleDuration' && Object.keys(a)[i] !== 'refSampleDurationFixed' && itema !== itemb) {
            return false;
          }
        } else if (itema.byteLength !== undefined) {
          if (itemb.byteLength === undefined) {
            return false;
          }
          if (!TsDemuxer.compareArray(itema, itemb, 'Uint8Array')) {
            return false;
          }
        } else if (itema.length !== undefined) {
          if (itemb.length === undefined) {
            return false;
          }
          if (!TsDemuxer.compareArray(itema, itemb, 'Array')) {
            return false;
          }
        } else {
          if (!TsDemuxer.compareMeta(itema, itemb)) {
            return false;
          }
        }
      }
      return true;
    }
  }, {
    key: 'mergeVideoES',
    value: function mergeVideoES(buffers) {
      var data = void 0;
      var length = 0;
      var offset = 0;
      for (var i = 0; i < buffers.length; i++) {
        length += buffers[i].length - buffers[i].position;
      }

      data = new Uint8Array(length);
      for (var _i3 = 0; _i3 < buffers.length; _i3++) {
        var buffer = buffers[_i3];
        data.set(new Uint8Array(buffer.buffer, buffer.position), offset);
        offset += buffer.length - buffer.position;
      }
      return new XGDataView(data.buffer);
    }
  }, {
    key: 'mergeAudioES',
    value: function mergeAudioES(buffers) {
      var data = void 0;
      var length = 0;
      var offset = 0;
      for (var i = 0; i < buffers.length; i++) {
        length += buffers[i].length;
      }

      data = new Uint8Array(length);
      for (var _i4 = 0; _i4 < buffers.length; _i4++) {
        var buffer = buffers[_i4];
        data.set(new Uint8Array(buffer.buffer), offset);
        offset += buffer.length;
      }

      return new XGDataView(data.buffer);
    }
  }, {
    key: 'read',
    value: function read(stream, ts, frags) {
      TsDemuxer.readHeader(stream, ts);
      TsDemuxer.readPayload(stream, ts, frags);
      // console.log('start', window.performance.now());
      // console.log('end', window.performance.now());
      if (ts.header.packet === 'MEDIA' && ts.header.payload === 1 && !ts.unknownPIDs) {
        ts.pes = TsDemuxer.PES(ts);
      }
    }
  }, {
    key: 'readPayload',
    value: function readPayload(stream, ts, frags) {
      var header = ts.header;
      var pid = header.pid;
      switch (pid) {
        case 0:
          TsDemuxer.PAT(stream, ts, frags);
          break;
        case 1:
          TsDemuxer.CAT(stream, ts, frags);
          break;
        case 2:
          TsDemuxer.TSDT(stream, ts, frags);
          break;
        case 0x1fff:
          break;
        default:
          var isPMT = false;
          for (var i = 0, len = frags.pat.length; i < len; i++) {
            if (frags.pat[i].pid === pid) {
              isPMT = true;
              break;
            }
          }
          // TODO: some的写法不太好，得改
          if (isPMT) {
            TsDemuxer.PMT(stream, ts, frags);
          } else {
            var sts = [];
            for (var _i5 = 0, _len = frags.pmt.length; _i5 < _len; _i5++) {
              if (frags.pmt[_i5].pid === pid) {
                sts.push(frags.pmt[_i5]);
                break;
              }
            }
            if (sts.length > 0) {
              var streamType = sts[0].streamType;
              TsDemuxer.Media(stream, ts, streamType);
            } else {
              ts.unknownPIDs = true;
            }
          }
      }
    }
  }, {
    key: 'readHeader',
    value: function readHeader(stream, ts) {
      var header = {};
      header.sync = stream.readUint8();
      var next = stream.readUint16();
      header.error = next >>> 15;
      header.payload = next >>> 14 & 1;
      header.priority = next >>> 13 & 1;
      header.pid = next & 0x1fff;

      next = stream.readUint8();

      header.scrambling = next >> 6 & 0x3; // 是否加密，00表示不加密

      /**
       * 00 ISO/IEC未来使用保留
       * 01 没有调整字段，仅含有184B有效净荷
       * 02 没有有效净荷，仅含有183B调整字段
       * 03 0~182B调整字段后为有效净荷
       */
      header.adaptation = next >> 4 & 0x3;
      header.continuity = next & 15;
      header.packet = header.pid === 0 ? 'PAT' : 'MEDIA';
      ts.header = header;
    }
  }, {
    key: 'PAT',
    value: function PAT(stream, ts, frags) {
      var ret = {};
      var next = stream.readUint8();
      stream.skip(next);
      next = stream.readUint8();
      ret.tabelID = next;
      next = stream.readUint16();
      ret.error = next >>> 7;
      ret.zero = next >>> 6 & 1;
      ret.sectionLength = next & 0xfff;
      ret.streamID = stream.readUint16();
      ret.current = stream.readUint8() & 1;
      ret.sectionNumber = stream.readUint8();
      ret.lastSectionNumber = stream.readUint8();
      var N = (ret.sectionLength - 9) / 4;
      var list = [];
      for (var i = 0; i < N; i++) {
        var programNumber = stream.readUint16();
        var pid = stream.readUint16() & 0x1fff;
        list.push({
          program: programNumber,
          pid: pid,
          type: programNumber === 0 ? 'network' : 'mapPID'
        });
      }
      if (list.length > 0) {
        frags.pat = frags.pat.concat(list);
      }
      ret.list = list;
      ret.program = stream.readUint16();
      ret.pid = stream.readUint16() & 0x1fff;
      ts.payload = ret;
      // TODO CRC
    }

    /**
     * ISO-13818-1 Table-2-25
     * payload 针对 PMT:
     *  1 字节： table id [table 第一字节]
     *  section_length : 12bit 【table 第2字节后四位，3字节】
     *  program_number : 16bit 【table第4，5字节】
     *  program_info_length : 12bit 【table 第11字节后 4bit + 第12 byte】
     *  N * 8 : descriptor
     *    stream_type : 8bit
     *    pid
     *
     * // 4 字节
     *  reserved : 3bit
     *  elementary_PID : 13bit
     *  reserved : 4bit
     *  ES_info_length: 12bit
     */

  }, {
    key: 'PMT',
    value: function PMT(stream, ts, frags) {
      var header = ts.header;
      header.packet = 'PMT';

      var offset = stream.position;

      offset += stream.getUint8(offset);

      offset += 1; // add 1 byte for table id

      var sectionLength = (stream.getUint8(offset + 1) & 0x0f) << 8 | stream.getUint8(offset + 2);

      var tableEnd = offset + 3 + sectionLength - 4;

      var programInfoLength = (stream.getUint8(offset + 10) & 0x0f) << 8 | stream.getUint8(offset + 11);

      offset += 12 + programInfoLength;

      var list = [];

      while (offset < tableEnd) {
        var pid = (stream.getUint8(offset + 1) & 0x1f) << 8 | stream.getUint8(offset + 2);
        list.push({
          streamType: stream.getUint8(offset),
          pid: pid
        });
        offset += ((stream.getUint8(offset + 3) & 0x0f) << 8 | stream.getUint8(offset + 4)) + 5;
      }
      frags.pmt = list;
      stream.skip(tableEnd + 4);
    }
  }, {
    key: 'Media',
    value: function Media(stream, ts, streamType) {
      var header = ts.header;
      var payload = {};

      var _StreamType$streamTyp = _slicedToArray(StreamType[streamType], 2),
          type = _StreamType$streamTyp[0],
          codec = _StreamType$streamTyp[1];

      header.streamType = streamType;
      header.type = type;
      header.codec = codec;

      if (header.adaptation === 0x03) {
        payload.adaptationLength = stream.readUint8();
        if (payload.adaptationLength > 0) {
          var next = stream.readUint8();
          payload.discontinue = next >>> 7;
          payload.access = next >>> 6 & 0x01;
          payload.priority = next >>> 5 & 0x01;
          payload.PCR = next >>> 4 & 0x01;
          payload.OPCR = next >>> 3 & 0x01;
          payload.splicePoint = next >>> 2 & 0x01;
          payload.transportPrivate = next >>> 1 & 0x01;
          payload.adaptationField = next & 0x01;
          var _start = stream.position;
          if (payload.PCR === 1) {
            payload.programClockBase = stream.readUint32() << 1;
            next = stream.readUint16();
            payload.programClockBase |= next >>> 15;
            payload.programClockExtension = next & 0x1ff;
          }
          if (payload.OPCR === 1) {
            payload.originProgramClockBase = stream.readUint32() << 1;
            next = stream.readUint16();
            payload.originProgramClockBase += next >>> 15;
            payload.originProgramClockExtension = next & 0x1ff;
          }
          if (payload.splicePoint === 1) {
            payload.spliceCountdown = stream.readUint8();
          }
          if (payload.transportPrivate === 1) {
            var length = stream.readUint8();
            var transportPrivateData = [];
            for (var i = 0; i < length; i++) {
              transportPrivateData.push(stream.readUint8());
            }
          }
          if (payload.adaptationField === 1) {
            var _length = stream.readUint8();
            var _next = stream.readUint8();
            var start = stream.position;
            var ltw = _next >>> 7;
            var piecewise = _next >>> 6 & 0x1;
            var seamless = _next >>> 5 & 0x1;
            if (ltw === 1) {
              _next = stream.readUint16();
              payload.ltwValid = _next >>> 15;
              payload.ltwOffset = _next & 0xefff;
            }
            if (piecewise === 1) {
              _next = stream.readUint24();
              payload.piecewiseRate = _next & 0x3fffff;
            }
            if (seamless === 1) {
              _next = stream.readInt8();
              payload.spliceType = _next >>> 4;
              payload.dtsNextAU1 = _next >>> 1 & 0x7;
              payload.marker1 = _next & 0x1;
              _next = stream.readUint16();
              payload.dtsNextAU2 = _next >>> 1;
              payload.marker2 = _next & 0x1;
              _next = stream.readUint16();
              payload.dtsNextAU3 = _next;
            }
            stream.skip(_length - 1 - (stream.position - start));
          }
          var lastStuffing = payload.adaptationLength - 1 - (stream.position - _start);
          stream.skip(lastStuffing);
        }
      }
      payload.stream = new XGDataView(stream.buffer.slice(stream.position));
      ts.payload = payload;
    }

    /**
     * http://dvd.sourceforge.net/dvdinfo/pes-hdr.html
     * ISO-13818-1 Table-2-17
     * packet_start_code_prefix : 24bit 0x000001
     * stream_id : 8bit
     * PES_packet_length : 16bit
     * PTS_DTS_flags : 2bit 【PES_packet_length后第2字节前2位】& 0xc0  [0x10 0x11]
     * PES_header_data_length : 【PTS_DTS_flags 后第1字节】
     *
     * | 6字节header | 3字节扩展 | PES Header data | payload |
     */

  }, {
    key: 'PES',
    value: function PES(ts) {
      var ret = {};
      var buffer = ts.payload.stream;

      var next = buffer.readUint24();
      if (next !== 1) {
        ret.ES = {};
        ret.ES.buffer = buffer;
      } else {
        var streamID = buffer.readUint8();
        if (streamID >= 0xe0 && streamID <= 0xef) {
          ret.type = 'video';
        }
        if (streamID >= 0xc0 && streamID <= 0xdf) {
          ret.type = 'audio';
        }
        var packetLength = buffer.readUint16();
        ret.packetLength = packetLength;
        if (ret.type === 'video' || ret.type === 'audio') {
          var _next2 = buffer.readUint8();
          var first = _next2 >>> 6;
          if (first !== 0x02) {
            throw new Error('error when parse pes header');
          }
          _next2 = buffer.readUint8();
          ret.ptsDTSFlag = _next2 >>> 6;
          ret.escrFlag = _next2 >>> 5 & 0x01;
          ret.esRateFlag = _next2 >>> 4 & 0x01;
          ret.dsmFlag = _next2 >>> 3 & 0x01;
          ret.additionalFlag = _next2 >>> 2 & 0x01;
          ret.crcFlag = _next2 >>> 1 & 0x01;
          ret.extensionFlag = _next2 & 0x01;
          ret.pesHeaderLength = buffer.readUint8();
          var N1 = ret.pesHeaderLength;

          // 计算pts
          var pts = [];

          pts.push(buffer.readUint8());
          pts.push(buffer.readUint8());
          pts.push(buffer.readUint8());
          pts.push(buffer.readUint8());
          pts.push(buffer.readUint8());

          var p = (pts[0] & 0x0e) * 536870912 + // 1 << 29
          (pts[1] & 0xff) * 4194304 + // 1 << 22
          (pts[2] & 0xfe) * 16384 + // 1 << 14
          (pts[3] & 0xff) * 128 + // 1 << 7
          (pts[4] & 0xfe) / 2;
          // 不经过任何处理的原始的pts
          ret.purePts = p;
          buffer.dataview.position -= 5;
          pts = [];

          _next2 = buffer.readUint8();
          pts.push(_next2 >>> 1 & 0x07);
          _next2 = buffer.readUint16();
          pts.push(_next2 >>> 1);
          _next2 = buffer.readUint16();
          pts.push(_next2 >>> 1);
          ret.pts = pts[0] << 30 | pts[1] << 15 | pts[2];
          N1 -= 5;

          // 视频如果没有dts用pts
          if (ret.type === 'video') {
            ret.dts = ret.pts;
          }

          if (ret.ptsDTSFlag === 3) {
            var dts = [];
            _next2 = buffer.readUint8();
            dts.push(_next2 >>> 1 & 0x07);
            _next2 = buffer.readUint16();
            dts.push(_next2 >>> 1);
            _next2 = buffer.readUint16();
            dts.push(_next2 >>> 1);
            ret.dts = dts[0] << 30 | dts[1] << 15 | dts[2];
            N1 -= 5;
          }

          if (ret.escrFlag === 1) {
            var escr = [];
            var ex = [];
            _next2 = buffer.readUint8();
            escr.push(_next2 >>> 3 & 0x07);
            escr.push(_next2 & 0x03);
            _next2 = buffer.readUint16();
            escr.push(_next2 >>> 13);
            escr.push(_next2 & 0x03);
            _next2 = buffer.readUint16();
            escr.push(_next2 >>> 13);
            ex.push(_next2 & 0x03);
            _next2 = buffer.readUint8();
            ex.push(_next2 >>> 1);
            ret.escr = (escr[0] << 30 | escr[1] << 28 | escr[2] << 15 | escr[3] << 13 | escr[4]) * 300 + (ex[0] << 7 | ex[1]);
            N1 -= 6;
          }
          if (ret.esRateFlag === 1) {
            _next2 = buffer.readUint24();
            ret.esRate = _next2 >>> 1 & 0x3fffff;
            N1 -= 3;
          }
          if (ret.dsmFlag === 1) {
            throw new Error('not support DSM_trick_mode');
          }
          if (ret.additionalFlag === 1) {
            _next2 = buffer.readUint8();
            ret.additionalCopyInfo = _next2 & 0x7f;
            N1 -= 1;
          }
          if (ret.crcFlag === 1) {
            ret.pesCRC = buffer.readUint16();
            N1 -= 2;
          }
          if (ret.extensionFlag === 1) {
            throw new Error('not support extension');
          }
          if (N1 > 0) {
            buffer.skip(N1);
          }
          if (ret.dts > ret.pts) {
            ret.dts = ret.pts;
          }
          ret.ES = TsDemuxer.ES(buffer, ret.type, ts.header.streamType);
        } else {
          throw new Error('format is not supported');
        }
      }
      return ret;
    }
  }, {
    key: 'ES',
    value: function ES(buffer, type, streamType) {
      var ret = {};
      if (type === 'video') {
        // TODO readnalu
        ret.buffer = buffer;
      } else if (type === 'audio') {
        if (streamType === 0x0f || streamType === 0x11) {
          ret = TsDemuxer.parseADTSHeader(buffer);
        }
        ret.buffer = buffer;
      } else {
        throw new Error('ES ' + type + ' is not supported');
      }

      return ret;
    }
  }, {
    key: 'parseADTSHeader',
    value: function parseADTSHeader(buffer) {
      var ret = {};
      var next = buffer.readUint16();
      // adts的同步字节，12位
      if (next >>> 4 !== 0xfff) {
        throw new Error('aac ES parse Error');
      }
      var fq = [96000, 88200, 64000, 48000, 44100, 32000, 24000, 22050, 16000, 12000, 11025, 8000, 7350];
      ret.id = (next >>> 3 & 0x01) === 0 ? 'MPEG-4' : 'MPEG-2';
      ret.layer = next >>> 1 & 0x03;
      ret.absent = next & 0x01;
      next = buffer.readUint16();
      ret.audioObjectType = (next >>> 14 & 0x03) + 1;
      ret.profile = ret.audioObjectType - 1;
      ret.frequencyIndex = next >>> 10 & 0x0f;
      ret.frequence = fq[ret.frequencyIndex];
      ret.channel = next >>> 6 & 0x07;
      ret.frameLength = (next & 0x03) << 11 | buffer.readUint16() >>> 5;
      TsDemuxer.getAudioConfig(ret);
      buffer.skip(1);
      ret.buffer = buffer;
      return ret;
    }
  }, {
    key: 'TSDT',
    value: function TSDT(stream, ts, frags) {
      // TODO
      ts.payload = {};
    }
  }, {
    key: 'CAT',
    value: function CAT(stream, ts, frags) {
      var ret = {};
      ret.tableID = stream.readUint8();
      var next = stream.readUint16();
      ret.sectionIndicator = next >>> 7;
      ret.sectionLength = next & 0x0fff;
      stream.skip(2);
      next = stream.readUint8();
      ret.version = next >>> 3;
      ret.currentNextIndicator = next & 0x01;
      ret.sectionNumber = stream.readUint8();
      ret.lastSectionNumber = stream.readUint8();
      var N = (this.sectionLength - 9) / 4;
      var list = [];
      for (var i = 0; i < N; i++) {
        list.push({});
      }
      ret.crc32 = stream.readUint32();
      ts.payload = ret;
    }
  }, {
    key: 'getAudioConfig',
    value: function getAudioConfig(ret) {
      var userAgent = navigator.userAgent.toLowerCase();
      var config = void 0;
      var extensionSampleIndex = void 0;
      ret.originAudioObjectType = ret.audioObjectType;
      if (/firefox/i.test(userAgent)) {
        if (ret.frequencyIndex >= 8) {
          ret.audioObjectType = 5;
          config = new Array(4);
          extensionSampleIndex = ret.frequencyIndex - 3;
        } else {
          ret.audioObjectType = 2;
          config = new Array(2);
          extensionSampleIndex = ret.frequencyIndex;
        }
      } else if (userAgent.indexOf('android') !== -1) {
        ret.audioObjectType = 2;
        config = new Array(2);
        extensionSampleIndex = ret.frequencyIndex;
      } else {
        ret.audioObjectType = 5;
        config = new Array(4);
        if (ret.frequencyIndex >= 6) {
          extensionSampleIndex = ret.frequencyIndex - 3;
        } else {
          if (ret.channel === 1) {
            ret.audioObjectType = 2;
            config = new Array(2);
          }
          extensionSampleIndex = ret.frequencyIndex;
        }
      }

      config[0] = ret.audioObjectType << 3;
      config[0] |= (ret.frequencyIndex & 0x0e) >> 1;
      config[1] = (ret.frequencyIndex & 0x01) << 7;
      config[1] |= ret.channel << 3;
      if (ret.audioObjectType === 5) {
        config[1] |= (extensionSampleIndex & 0x0e) >> 1;
        config[2] = (extensionSampleIndex & 0x01) << 7;
        config[2] |= 2 << 2;
        config[3] = 0;
      }

      ret.audioConfig = config;
    }
  }]);

  return TsDemuxer;
}(EventEmitter);

export default TsDemuxer;