var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import { EVENTS } from 'xgplayer-helper-utils';
import { AudioTrack, VideoTrack } from 'xgplayer-helper-models';
import Demuxer from './ts-demuxer';
var DEMUX_EVENTS = EVENTS.DEMUX_EVENTS;

/**
 * @typedef {import('xgplayer-helper-models').TsFrag} TsFrag
 */

var TsDemuxer = function () {
  function TsDemuxer(configs) {
    _classCallCheck(this, TsDemuxer);

    this.configs = Object.assign({}, configs);
    this.demuxer = null;
  }

  _createClass(TsDemuxer, [{
    key: 'init',
    value: function init() {
      this.on(DEMUX_EVENTS.DEMUX_START, this.demux.bind(this));
    }
  }, {
    key: 'initDemuxer',
    value: function initDemuxer() {
      this.demuxer.on(Demuxer.EVENTS.METADATA_PARSED, this.onMetaDataParsed.bind(this));
      this.demuxer.on(Demuxer.EVENTS.VIDEO_SAMPLE_PARSED, this.onVideoSampleParsed.bind(this));
      this.demuxer.on(Demuxer.EVENTS.AUDIO_SAMPLE_PARSED, this.onAudioSampleParsed.bind(this));
      this.demuxer.on(Demuxer.EVENTS.SEI_PARSED, this.emit.bind(this, DEMUX_EVENTS.SEI_PARSED));
    }

    /**
     * @param {TsFrag} frag
     * @param {boolean} isVod
     */

  }, {
    key: 'demux',
    value: function demux(frag, isVod) {
      if (!this._tracks) {
        return;
      }
      if (!this._tracks.audioTrack) {
        this._tracks.audioTrack = new AudioTrack();
      }

      if (!this._tracks.videoTrack) {
        this._tracks.videoTrack = new VideoTrack();
      }

      if (!this.demuxer) {
        this.demuxer = new Demuxer(this._tracks);
        this.initDemuxer();
      }
      try {
        this.demuxer.demux(frag, this.inputBuffer, isVod);
      } catch (e) {
        this.emit(DEMUX_EVENTS.DEMUX_ERROR, this.TAG, e, false);
      }
      var hasVideo = this._tracks.videoTrack && this._tracks.videoTrack.samples.length ? 1 : 0;
      var hasAudio = this._tracks.audioTrack && this._tracks.audioTrack.samples.length ? 1 : 0;
      this.emit(DEMUX_EVENTS.DEMUX_COMPLETE, hasVideo, hasAudio);
    }
  }, {
    key: 'onMetaDataParsed',
    value: function onMetaDataParsed(type, meta) {
      var _tracks = this._tracks,
          videoTrack = _tracks.videoTrack,
          audioTrack = _tracks.audioTrack;

      var track = videoTrack;

      switch (type) {
        case 'video':
          track = videoTrack;
          break;
        case 'audio':
          track = audioTrack;
          break;
        default:
          track = videoTrack;
      }
      track.meta = meta;
      this.emit(DEMUX_EVENTS.METADATA_PARSED, type, meta);
    }
  }, {
    key: 'onVideoSampleParsed',
    value: function onVideoSampleParsed(sample) {
      if (sample.isKeyframe) {
        this.emit(DEMUX_EVENTS.ISKEYFRAME, sample.pts);
      }
      this._tracks.videoTrack.samples.push(sample);
    }
  }, {
    key: 'onAudioSampleParsed',
    value: function onAudioSampleParsed(sample) {
      this._tracks.audioTrack.samples.push(sample);
    }
  }, {
    key: 'destroy',
    value: function destroy() {
      this.off(DEMUX_EVENTS.DEMUX_START, this.demux);
      this.configs = {};
      this.demuxer && this.demuxer.destroy();
    }
  }, {
    key: 'inputBuffer',
    get: function get() {
      return this._context.getInstance(this.configs.inputbuffer);
    }
  }, {
    key: '_tracks',
    get: function get() {
      return this._context.getInstance('TRACKS');
    }
  }]);

  return TsDemuxer;
}();

export default TsDemuxer;