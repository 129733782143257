import { Tracks, Buffer as XgBuffer, Playlist } from 'xgplayer-helper-models';
import { Crypto, FetchLoader } from 'xgplayer-helper-utils';
import { M3U8Parser, TsDemuxer } from 'xgplayer-helper-transmuxers';

export default {
  Tracks: Tracks,
  XgBuffer: XgBuffer,
  FetchLoader: FetchLoader,
  Crypto: Crypto,
  M3U8Parser: M3U8Parser,
  TsDemuxer: TsDemuxer,
  Playlist: Playlist
};