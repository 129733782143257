var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import { TsFrag } from 'xgplayer-helper-models';

var REGEXP_ABSOLUTE_URL = /^(?:[a-zA-Z0-9+\-.]+:)?\/\//;
var REGEXP_URL_PAIR = /^((?:[a-zA-Z0-9+\-.]+:)?\/\/[^/?#]*)?([^?#]*\/)?/;

function getAbsoluteUrl(url, parentUrl) {
  if (!parentUrl || !url || REGEXP_ABSOLUTE_URL.test(url)) return url;
  var pairs = REGEXP_URL_PAIR.exec(parentUrl);
  if (!pairs) return url;
  if (url[0] === '/') return pairs[1] + url;
  return pairs[1] + pairs[2] + url;
}

/**
 * Reference: https://tools.ietf.org/html/rfc8216#section-4.3
 */

var M3U8Parser = function () {
  function M3U8Parser() {
    _classCallCheck(this, M3U8Parser);
  }

  _createClass(M3U8Parser, null, [{
    key: 'parse',

    /**
     * @param {string} text
     * @param {string} baseurl
     * @return {{duration: number}}
     */
    value: function parse(text) {
      var baseurl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

      var ret = {
        duration: 0
      };
      if (!text || !text.split) {
        return;
      }
      var refs = text.split(/\r|\n/);
      refs = refs.filter(function (ref) {
        return ref;
      });
      var ref = refs.shift();
      if (!ref.match('#EXTM3U')) {
        throw new Error('Invalid m3u8 file: not "#EXTM3U"');
      }
      ref = refs.shift();
      var nextDiscontinue = false;
      var nextId = 0;
      while (ref) {
        var refm = ref.match(/#(.[A-Z|-]*):(.*)/);
        var refd = ref.match(/#(.[A-Z|-]*)/);
        if (refd && refm && refm.length > 2) {
          switch (refm[1]) {
            case 'EXT-X-VERSION':
              ret.version = parseInt(refm[2]);
              break;
            case 'EXT-X-MEDIA-SEQUENCE':
              ret.sequence = parseInt(refm[2]);
              break;
            case 'EXT-X-TARGETDURATION':
              ret.targetduration = parseFloat(refm[2]);
              break;
            case 'EXTINF':
              nextId = M3U8Parser.parseFrag(refm, refs, ret, baseurl, nextDiscontinue, nextId);
              nextDiscontinue = false;
              break;
            case 'EXT-X-KEY':
              M3U8Parser.parseDecrypt(refm[2], ret);
              break;
            default:
              break;
          }
        }if (refd && refd.length > 1) {
          switch (refd[1]) {
            case 'EXT-X-DISCONTINUITY':
              nextDiscontinue = true;
              break;
            case 'EXT-X-ENDLIST':
              var last = ret.frags[ret.frags.length - 1];
              last.isLast = true;
              ret.end = true;
              break;
            default:
              break;
          }
        }
        ref = refs[nextId++];
      }
      return ret;
    }

    /**
     *
     * @param {string[]}refm
     * @param {string[]}refs
     * @param {*} ret
     * @param {string} baseurl
     * @param {boolean} discontinue
     * @param {number} nextId
     * @return {*}
     */

  }, {
    key: 'parseFrag',
    value: function parseFrag(refm, refs, ret, baseurl, discontinue, nextId) {
      if (!ret.frags) {
        ret.frags = [];
      }

      var frag = new TsFrag({
        start: ret.duration,
        duration: parseInt(parseFloat(refm[2]) * 1000)
      });

      if (frag.duration < 200) {
        return nextId;
      }

      ret.duration += frag.duration;
      var nextline = refs[nextId++];
      if (nextline.match(/#(.*):(.*)/) || nextline.match(/^#/)) {
        nextline = refs[nextId++];
      }

      frag.url = getAbsoluteUrl(nextline, baseurl);

      frag.discontinue = discontinue;
      // add id
      if (ret.frags.length) {
        var last = ret.frags[ret.frags.length - 1];
        frag.id = last.id + 1;
        frag.cc = discontinue ? last.cc + 1 : last.cc;
      } else {
        frag.id = ret.sequence || 1;
        frag.cc = 0;
      }
      ret.frags.push(frag);
      return nextId;
    }

    /**
     * @param {string[]}refm
     * @param {*} ret
     */

  }, {
    key: 'parseDecrypt',
    value: function parseDecrypt(refm, ret) {
      ret.encrypt = {};
      var refs = refm.split(',');
      for (var i in refs) {
        var cmd = refs[i];
        if (cmd.match(/METHOD=(.*)/)) {
          ret.encrypt.method = cmd.match(/METHOD=(.*)/)[1];
        }
        if (cmd.match(/URI="(.*)"/)) {
          ret.encrypt.uri = cmd.match(/URI="(.*)"/)[1];
        }

        if (cmd.match(/IV=0x(.*)/)) {
          var iv = cmd.match(/IV=0x(.*)/)[1];
          var length = Math.ceil(iv.length / 2);
          ret.encrypt.ivb = new Uint8Array(length);
          for (var _i = length - 1; _i >= 0; _i--) {
            var im = parseInt(iv.substr(_i * 2, 2), 16);
            ret.encrypt.ivb[_i] = im;
          }
          ret.encrypt.iv = iv;
        }
      };
    }

    /**
     * @param {string} url
     * @return {boolean}
     */

  }, {
    key: 'isHTTPS',
    value: function isHTTPS(url) {
      var httpsUrlRegex = /^https:\/\//i;
      return httpsUrlRegex.test(url);
    }
  }]);

  return M3U8Parser;
}();

export default M3U8Parser;