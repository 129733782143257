var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

export var RemuxedBuffer = function RemuxedBuffer() {
  _classCallCheck(this, RemuxedBuffer);

  /** @type{string} */
  this.mimetype = '';
  /** @type{Uint8Array|null} */
  this.init = null;
  /** @type{Uint8Array[]} */
  this.data = [];
  /** @type{number} */
  this.bufferDuration = 0;
};

var RemuxedBufferManager = function () {
  function RemuxedBufferManager() {
    _classCallCheck(this, RemuxedBufferManager);

    /** @type{Object.<string, RemuxedBuffer>} */
    this.sources = {};
  }

  /**
   *
   * @param {string} name
   * @return {RemuxedBuffer}
   */


  _createClass(RemuxedBufferManager, [{
    key: 'getSource',
    value: function getSource(name) {
      return this.sources[name];
    }

    /**
     * @param {string} name
     * @return {RemuxedBuffer}
     */

  }, {
    key: 'createSource',
    value: function createSource(name) {
      this.sources[name] = new RemuxedBuffer();
      return this.sources[name];
    }
  }, {
    key: 'clear',
    value: function clear() {
      this.sources = {};
    }
  }, {
    key: 'destroy',
    value: function destroy() {
      this.clear();
    }
  }]);

  return RemuxedBufferManager;
}();

export default RemuxedBufferManager;