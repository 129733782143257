var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import { EVENTS } from 'xgplayer-helper-utils';
import Remuxer from './remuxer';

var REMUX_EVENTS = EVENTS.REMUX_EVENTS;
var PLAYER_EVENTS = EVENTS.PLAYER_EVENTS;

var Mp4Remuxer = function () {
  function Mp4Remuxer() {
    var curTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

    _classCallCheck(this, Mp4Remuxer);

    this.TAG = 'Mp4Remuxer';
    this._curTime = curTime;
    if (!this.remuxer) {
      this.initRemuxer();
    }
  }

  _createClass(Mp4Remuxer, [{
    key: 'init',
    value: function init() {
      this.on(REMUX_EVENTS.REMUX_MEDIA, this.remux.bind(this));
      this.on(REMUX_EVENTS.REMUX_METADATA, this.onMetaDataReady.bind(this));
      this.on(REMUX_EVENTS.DETECT_CHANGE_STREAM, this.resetDtsBase.bind(this));
      this.on(REMUX_EVENTS.DETECT_FRAG_ID_DISCONTINUE, this.seek.bind(this));
      this.on(PLAYER_EVENTS.SEEK, this.seek.bind(this));
    }
  }, {
    key: 'initRemuxer',
    value: function initRemuxer() {
      this.remuxer = new Remuxer({
        audioMeta: null,
        videoMeta: null,
        curTime: this._curTime
      });
      this.remuxer.on(Remuxer.EVENTS.MEDIA_SEGMENT, this.writeToSource.bind(this));
      this.remuxer.on(Remuxer.EVENTS.TRACK_REMUXED, this.onTrackRemuxed.bind(this));
    }
  }, {
    key: 'remux',
    value: function remux() {
      if (!this.remuxer._videoMeta) {
        this.remuxer._videoMeta = this.videoMeta;
        this.remuxer._audioMeta = this.audioMeta;
      }

      var _context$getInstance = this._context.getInstance('TRACKS'),
          audioTrack = _context$getInstance.audioTrack,
          videoTrack = _context$getInstance.videoTrack;

      return this.remuxer.remux(audioTrack, videoTrack);
    }
  }, {
    key: 'resetDtsBase',
    value: function resetDtsBase() {
      this.remuxer && this.remuxer.resetDtsBase();
    }
  }, {
    key: 'seek',
    value: function seek(time) {
      this.remuxer && this.remuxer.seek(time);
    }
  }, {
    key: 'onMetaDataReady',
    value: function onMetaDataReady(type) {
      if (!this.remuxer) {
        this.initRemuxer();
      }
      var track = void 0;

      if (type === 'audio') {
        var _context$getInstance2 = this._context.getInstance('TRACKS'),
            audioTrack = _context$getInstance2.audioTrack;

        track = audioTrack;
      } else {
        var _context$getInstance3 = this._context.getInstance('TRACKS'),
            videoTrack = _context$getInstance3.videoTrack;

        track = videoTrack;
      }

      var presourcebuffer = this._context.getInstance('PRE_SOURCE_BUFFER');
      var source = presourcebuffer.getSource(type);
      if (!source) {
        source = presourcebuffer.createSource(type);
      }

      source.mimetype = track.meta.codec;
      source.init = this.remuxer.remuxInitSegment(type, track.meta);

      this.emit(REMUX_EVENTS.INIT_SEGMENT, type);
    }
  }, {
    key: 'onTrackRemuxed',
    value: function onTrackRemuxed(track) {
      this.emit(REMUX_EVENTS.MEDIA_SEGMENT, track);
    }
  }, {
    key: 'writeToSource',
    value: function writeToSource(type, buffer, bufferDuration) {
      var presourcebuffer = this._context.getInstance('PRE_SOURCE_BUFFER');
      var source = presourcebuffer.getSource(type);
      if (!source) {
        source = presourcebuffer.createSource(type);
      }
      source.data.push(buffer);
      if (bufferDuration) {
        source.bufferDuration = bufferDuration + (source.bufferDuration || 0);
      }
    }
  }, {
    key: 'destroy',
    value: function destroy() {
      if (this.remuxer) {
        this.remuxer.destroy();
      }
      this.remuxer = null;
    }
  }, {
    key: 'videoMeta',
    get: function get() {
      return this._context.getInstance('TRACKS').videoTrack.meta;
    }
  }, {
    key: 'audioMeta',
    get: function get() {
      return this._context.getInstance('TRACKS').audioTrack.meta;
    }
  }]);

  return Mp4Remuxer;
}();

export default Mp4Remuxer;