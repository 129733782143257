var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _set = function set(object, property, value, receiver) { var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent !== null) { set(parent, property, value, receiver); } } else if ("value" in desc && desc.writable) { desc.value = value; } else { var setter = desc.set; if (setter !== undefined) { setter.call(receiver, value); } } return value; };

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import Player from 'xgplayer';
import { EVENTS, common, Context } from 'xgplayer-helper-utils';
import HlsVodController from './hls-vod';

var debounce = common.debounce;

var HlsAllowedEvents = EVENTS.HlsAllowedEvents;
var REMUX_EVENTS = EVENTS.REMUX_EVENTS;
var HLS_EVENTS = EVENTS.HLS_EVENTS;
var MSE_EVENTS = EVENTS.MSE_EVENTS;

var HlsVodPlayer = function (_Player) {
  _inherits(HlsVodPlayer, _Player);

  function HlsVodPlayer(options) {
    _classCallCheck(this, HlsVodPlayer);

    var _this = _possibleConstructorReturn(this, (HlsVodPlayer.__proto__ || Object.getPrototypeOf(HlsVodPlayer)).call(this, options));

    _this.hlsOps = {};
    _this.util = Player.util;
    _this.util.deepCopy(_this.hlsOps, options);
    _this._handleSetCurrentTime = debounce(_this._handleSetCurrentTime.bind(_this), 200);
    _this.onWaiting = _this.onWaiting.bind(_this);
    // this.started = false;
    return _this;
  }

  _createClass(HlsVodPlayer, [{
    key: '_handleSetCurrentTime',
    value: function _handleSetCurrentTime(time) {
      time = parseFloat(time);
      if (time === this.currentTime) return;
      _set(HlsVodPlayer.prototype.__proto__ || Object.getPrototypeOf(HlsVodPlayer.prototype), 'currentTime', time, this);
      if (this._context) {
        this.__core__.seek(time);
      }
    }
  }, {
    key: 'play',
    value: function play() {
      var _this2 = this;

      return this.video.play().catch(function (e) {
        if (e && e.code === 20) {
          // fix: chrome The play() request was interrupted by a new load request.
          _this2.once('canplay', function () {
            _this2.video.play();
          });
        }
      });
    }
  }, {
    key: 'replay',
    value: function replay() {
      var _this3 = this;

      this.__core__.mse.cleanBuffers().then(function () {
        _this3.__core__.replay();
        _get(HlsVodPlayer.prototype.__proto__ || Object.getPrototypeOf(HlsVodPlayer.prototype), 'replay', _this3).call(_this3);
      });
    }
  }, {
    key: '_initEvents',
    value: function _initEvents() {
      var _this4 = this;

      this.__core__.once(REMUX_EVENTS.INIT_SEGMENT, function () {
        var mse = _this4.__core__.mse;
        _get(HlsVodPlayer.prototype.__proto__ || Object.getPrototypeOf(HlsVodPlayer.prototype), 'start', _this4).call(_this4, mse.url);
      });

      this.__core__.once(HLS_EVENTS.RETRY_TIME_EXCEEDED, function () {
        _this4.emit('error', new Player.Errors('network', _this4.config.url));
      });

      this.__core__.on(MSE_EVENTS.SOURCE_UPDATE_END, function () {
        _this4._onSourceUpdateEnd();
      });

      this.once('canplay', function () {
        if (_this4.config.autoplay) {
          _this4.play();
        }
      });
    }
  }, {
    key: 'initHlsBackupEvents',
    value: function initHlsBackupEvents(hls, ctx) {
      var _this5 = this;

      hls.once(REMUX_EVENTS.INIT_SEGMENT, function () {
        if (!_this5.video.src) {
          console.log('挂载 src blob');
          var mse = hls.mse;
          _get(HlsVodPlayer.prototype.__proto__ || Object.getPrototypeOf(HlsVodPlayer.prototype), 'start', _this5).call(_this5, mse.url);
        }
      });
      hls.once(EVENTS.REMUX_EVENTS.MEDIA_SEGMENT, function () {
        _this5.__core__ = hls;
        _this5.__core__.mse.cleanBuffers().then(function () {
          _this5.__core__.mse.resetContext(ctx);
          _this5.__core__.mse.doAppend();
          _this5._context = ctx;
        });
      });

      hls.once(EVENTS.LOADER_EVENTS.LOADER_ERROR, function () {
        ctx.destroy();
      });
    }
  }, {
    key: '_onSourceUpdateEnd',
    value: function _onSourceUpdateEnd() {
      if (this.video.readyState === 1 || this.video.readyState === 2) {
        var _detectBufferGap = this.detectBufferGap(),
            gap = _detectBufferGap.gap,
            start = _detectBufferGap.start,
            method = _detectBufferGap.method;

        if (gap) {
          if (method === 'ceil' && this.currentTime < Math[method](start)) {
            this.currentTime = Math[method](start);
          } else if (method === 'floor' && this.currentTime > Math[method](start)) {
            this.currentTime = Math[method](start);
          }
        }
      }
    }
  }, {
    key: 'start',
    value: function start() {
      var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.config.url;

      if (!url || this.started) {
        return;
      }
      if (!this._context) {
        this._context = new Context(this, this.hlsOps, HlsAllowedEvents);
      }

      this.__core__ = this._context.registry('HLS_VOD_CONTROLLER', HlsVodController)({ player: this, container: this.video, preloadTime: this.config.preloadTime });
      this._context.init();
      this.__core__.load(url);
      this._initEvents();

      this.started = true;
    }
  }, {
    key: 'switchURL',
    value: function switchURL(url) {
      this.config.url = url;
      var context = new Context(this, this.hlsOps, HlsAllowedEvents);
      var hls = context.registry('HLS_VOD_CONTROLLER', HlsVodController)({
        player: this,
        container: this.video,
        mse: this.__core__.mse,
        preloadTime: this.config.preloadTime
      });
      this.newContext = context;
      this.newHls = hls;
      context.init();
      this._context.destroy();
      this.initHlsBackupEvents(hls, context);
      this.__core__.mse.cleanBuffers().then(function () {
        hls.load(url);
      });
    }
  }, {
    key: 'destroy',
    value: function destroy() {
      var _this6 = this;

      return new Promise(function (resolve) {
        if (_this6.__core__ && _this6.__core__.mse) {
          _this6.__core__.mse.destroy().then(function () {
            if (_this6._context) {
              _this6._context.destroy();
            }
            _get(HlsVodPlayer.prototype.__proto__ || Object.getPrototypeOf(HlsVodPlayer.prototype), 'destroy', _this6).call(_this6);
            resolve();
          });
          setTimeout(function () {
            resolve();
          }, 100);
        } else {
          _get(HlsVodPlayer.prototype.__proto__ || Object.getPrototypeOf(HlsVodPlayer.prototype), 'destroy', _this6).call(_this6);
          setTimeout(function () {
            resolve();
          }, 50);
        }
      });
    }
  }, {
    key: 'detectBufferGap',
    value: function detectBufferGap() {
      var video = this.video;

      var result = {
        gap: false,
        start: -1
      };
      var isStartPlay = this.currentTime === 0;
      for (var i = 0; i < video.buffered.length; i++) {
        var bufferStart = video.buffered.start(i);
        var bufferEnd = video.buffered.end(i);
        if ((!video.played.length || bufferStart <= this.currentTime && bufferEnd - this.currentTime >= 0.5) && !isStartPlay) {
          break;
        }
        var startGap = bufferStart - this.currentTime;
        var endGap = this.currentTime - bufferEnd;
        if (startGap > 0.01 && (startGap <= 2 || isStartPlay)) {
          result = {
            gap: true,
            start: bufferStart,
            method: 'ceil'
          };
          break;
        } else if (endGap > 0.1 && (endGap <= 2 || isStartPlay)) {
          result = {
            gap: true,
            start: bufferEnd,
            method: 'floor'
          };
        } else {
          result = {
            gap: false,
            start: -1
          };
        }
      }

      return result;
    }
  }, {
    key: 'currentTime',
    get: function get() {
      return _get(HlsVodPlayer.prototype.__proto__ || Object.getPrototypeOf(HlsVodPlayer.prototype), 'currentTime', this);
    },
    set: function set(time) {
      this._handleSetCurrentTime(time);
    }
  }, {
    key: 'src',
    get: function get() {
      return this.currentSrc;
    },
    set: function set(url) {
      var _this7 = this;

      this.onWaiting = this.onWaiting.bind(this);
      this.__core__.mse.destroy().then(function () {
        _this7._context.destroy();
        _this7._context = null;
        _this7.video.src = '';
        _this7.video.load();
        _this7.started = false;
        _this7.video.currentTime = 0;

        if (!_this7.paused) {
          _this7.pause();
          _this7.once('canplay', function () {
            _this7.play();
          });
        } else {
          _this7.play();
        }

        _this7.start(url);
      });
    }
  }, {
    key: 'context',
    get: function get() {
      return this._context;
    }
  }], [{
    key: 'isSupported',
    value: function isSupported() {
      return window.MediaSource && window.MediaSource.isTypeSupported('video/mp4; codecs="avc1.42E01E,mp4a.40.2"');
    }
  }, {
    key: 'install',
    value: function install(name, plugin) {
      return Player.install(name, plugin);
    }
  }]);

  return HlsVodPlayer;
}(Player);

export default HlsVodPlayer;