var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * @typedef {Object} TAudioSample
 * @property {number} dts
 * @property {number} pts
 * @property {number} originDts
 * @property {Uint8Array} data
 */

/**
 * @typedef {Object} TVideoSample
 * @property {number} dts
 * @property {number} pts
 * @property {boolean} isKeyframe
 * @property {number} originDts
 * @property {Uint8Array} data
 * @property {Array<any>} nals
 */

export var AudioSample = function () {
  function AudioSample(info) {
    _classCallCheck(this, AudioSample);

    var _default = AudioSample.getDefault();
    if (!info) {
      return _default;
    }
    return Object.assign({}, _default, info);
  }

  /**
   * default audio sample
   * @return {TAudioSample}
   */


  _createClass(AudioSample, null, [{
    key: "getDefault",
    value: function getDefault() {
      return {
        dts: -1,
        pts: -1,
        originDts: -1,
        data: new Uint8Array()
      };
    }
  }]);

  return AudioSample;
}();

export var VideoSample = function () {
  /**
   * @constructor
   * @param info
   * @return {TVideoSample}
   */
  function VideoSample(info) {
    _classCallCheck(this, VideoSample);

    var _default = VideoSample.getDefault();

    if (!info) {
      return _default;
    }
    var sample = Object.assign({}, _default, info);

    return sample;
  }

  /**
   * default video sample
   * @return {TVideoSample}
   */


  _createClass(VideoSample, null, [{
    key: "getDefault",
    value: function getDefault() {
      return {
        dts: -1,
        pts: undefined,
        isKeyframe: false, // is Random access point
        originDts: -1,
        data: new Uint8Array(),
        nals: []
      };
    }
  }]);

  return VideoSample;
}();