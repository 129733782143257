var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * @typedef {Object} TSFrag
 * @property {number} duration
 * @property {boolean} downloaded
 * @property {boolean} downloading
 * @property {number=} start
 * @property {number} cc
 * @property {boolean} discontinue
 */

var Playlist = function () {
  function Playlist(configs) {
    _classCallCheck(this, Playlist);

    /** @type {string} */
    this._baseURL = '';
    /** @type {Object.<number, string>} */
    this._list = {};
    /** @type {Object.<string, TSFrag>} */
    this._ts = {};
    /** @type {number} */
    this.version = 0;
    /** @type {number} */
    this.sequence = -1;
    /** @type {number} */
    this.targetduration = 0;
    /** @type {number} */
    this.duration = 0;
    /** @type {number} */
    this.fragLength = 0;
    /** @type {TSFrag | undefined} */
    this._lastget = undefined;
    /** @type {boolean} */
    this.end = false; // 判断live | vod , 对点播或直播结束时存在 EXT-X-ENDLIST
    /** @type {boolean} */
    this.autoclear = configs.autoclear || false;
    /** @type {*} */
    this.logger = configs.logger;
    /** @type {string[]} */
    this.downloadedUrls = [];
    this._avgSegmentDuration = 4;
  }

  /**
   * @return {Object<number, string>}
   */


  _createClass(Playlist, [{
    key: 'push',


    /**
     * add a ts frag to play list
     * @param {string} tsURL ts frag url
     * @param {number} duration ts frag duration
     * @param {boolean} discontinue has #EXT-X-DISCONTINUITY tag before this frag
     * @param {number} id
     * @param {number} cc
     */
    value: function push(tsURL, duration, discontinue, id, cc, isLast) {
      if (!this._ts[tsURL]) {
        this._ts[tsURL] = {
          duration: duration,
          downloaded: false,
          downloading: false,
          start: this.duration,
          discontinue: !!discontinue,
          id: id,
          cc: cc,
          isLast: isLast || false
        };
        this._list[this.duration] = tsURL;
        this.duration += duration;
        this.fragLength += 1;
      }
    }

    /**
     * delete a fragment from playlist
     * @param {string} url
     */

  }, {
    key: 'deleteFrag',
    value: function deleteFrag(url) {
      if (this._ts[url]) {
        if (this._ts[url].start > this._lastget.time) {
          this._lastget = {
            duration: this._ts[url].duration,
            time: this._ts[url].start,
            downloaded: false,
            downloading: false,
            url: url,
            id: this._ts[url].id
          };
        }
        delete this._list[this._ts[url].start];
        delete this._ts[url];
        this.fragLength -= 1;
      }
    }
  }, {
    key: '_calcAvgFrgmentDuration',
    value: function _calcAvgFrgmentDuration(data) {
      if (!data.frags) return data.targetduration;
      var fragLen = data.frags.length;
      return Math.floor(data.duration / fragLen / 1000);
    }

    /**
     * add m3u8 to current play list
     * @param {*} data
     * @param {boolean} deletepre
     */

  }, {
    key: 'pushM3U8',
    value: function pushM3U8(data, deletepre) {
      // 常规信息替换
      if (!data) {
        throw new Error('No m3u8 data received.');
      }
      this.version = data.version;

      this.targetduration = data.targetduration;

      this._avgSegmentDuration = Math.min(this.targetduration, this._calcAvgFrgmentDuration(data));

      if (data.encrypt && !this.encrypt) {
        this.encrypt = data.encrypt;
      }

      this.end = data.end || false;

      if (!data.sequence) {
        data.sequence = 0;
      }

      // 新分片信息
      if (data.sequence > this.sequence) {
        var len = data.frags.length;
        if (this.logger) {
          this.logger.log('PLAYLIST', 'new playlist [' + data.sequence + ', ' + (data.sequence + len - 1) + ']');
        }
        this.sequence = data.sequence;
        var newfraglist = [];
        for (var i = 0; i < len; i++) {
          var frag = data.frags[i];
          if (!this._ts[frag.url] && this.downloadedUrls.indexOf(frag.url) < 0) {
            newfraglist.push(frag.url);
            this.push(frag.url, frag.duration, frag.discontinue, frag.id, frag.cc, frag.isLast);
          }
        }

        if (newfraglist.length < 1) {
          throw new Error('Can not read ts file list.');
        }

        if (deletepre) {
          var tslist = this.getTsList();
          for (var _i = 0; _i < tslist.length; _i++) {
            if (newfraglist.indexOf(tslist[_i]) < 0) {
              this.deleteFrag(tslist[_i]);
            }
          }
        }
      } else {
        throw new Error('Old m3u8 file received, ' + data.sequence);
      }
    }

    /**
     * return ts url list
     * @return {string[]}
     */

  }, {
    key: 'getTsList',
    value: function getTsList() {
      return Object.keys(this._ts);
    }

    /**
     * mark a ts frag as downloaded / not downloaded
     * @param {string} tsname
     * @param {boolean} isloaded
     */

  }, {
    key: 'downloaded',
    value: function downloaded(tsname, isloaded) {
      var ts = this._ts[tsname];
      if (ts) {
        ts.downloaded = isloaded;
      }
    }

    /**
     * mark a ts frag as loading / not loading
     * @param {string} tsname
     * @param {boolean} loading
     */

  }, {
    key: 'downloading',
    value: function downloading(tsname, loading) {
      var ts = this._ts[tsname];
      if (ts) {
        ts.downloading = loading;
      }
    }

    /**
     * get a frag by ts name
     * @param name
     * @return {TSFrag}
     */

  }, {
    key: 'getTsByName',
    value: function getTsByName(name) {
      return this._ts[name];
    }

    /**
     * get ts frag by timestamp
     * @param {number} time
     * @return {undefined|TSFrag}
     */

  }, {
    key: 'getTs',
    value: function getTs(time) {
      var timelist = Object.keys(this._list);
      var ts = void 0;

      if (time === undefined) {
        if (this._lastget) {
          time = this._lastget.time + this._lastget.duration;
        } else {
          time = 0;
        }
      }

      if (timelist.length < 1 || time >= this.duration) {
        return undefined;
      }
      timelist = timelist.sort(function (a, b) {
        return parseFloat(a) - parseFloat(b);
      });
      for (var i = 0; i < timelist.length; i++) {
        if (time >= parseInt(timelist[i])) {
          var url = this._list[timelist[i]];
          var downloaded = this._ts[url].downloaded;
          var downloading = this._ts[url].downloading;
          ts = {
            url: url,
            downloaded: downloaded,
            downloading: downloading,
            time: parseInt(timelist[i]),
            duration: parseInt(this._ts[url].duration),
            id: this._ts[url].id,
            cc: this._ts[url].cc,
            isLast: this._ts[url].isLast
          };
          if (this.autoclear && this._lastget) {
            delete this._ts[this._lastget.url];
            delete this._list[this._lastget.time];
          }
          this._lastget = ts;
        } else {
          break;
        }
      }
      if (ts) {
        this.downloadedUrls.push(ts.url);
      }
      return ts;
    }

    /**
     * get last downloaded ts frag
     * @return {TSFrag|undefined}
     */

  }, {
    key: 'getLastDownloadedTs',
    value: function getLastDownloadedTs() {
      var timelist = Object.keys(this._list).sort(function (a, b) {
        var result = Number(a) - Number(b);
        return result;
      });
      var found = void 0;
      for (var i = 0; i < timelist.length; i++) {
        var url = this._list[timelist[i]];
        var downloaded = this._ts[url].downloaded;
        var downloading = this._ts[url].downloading;
        if (downloaded) {
          found = { url: url, downloaded: downloaded, downloading: downloading, time: parseInt(timelist[i]), duration: parseInt(this._ts[url].duration) };
        } else {
          break;
        }
      }

      return found;
    }
  }, {
    key: 'clear',
    value: function clear() {
      this._baseURL = '';
      this._list = {};
      this._ts = {};
      this.version = 0;
      this.sequence = -1;
      this.targetduration = 0;
      this.duration = 0;
    }
  }, {
    key: 'clearDownloaded',
    value: function clearDownloaded() {
      var list = Object.keys(this._ts);
      for (var i = 0, l = list.length; i < l; i++) {
        var ts = this._ts[list[i]];
        ts.downloaded = false;
        ts.downloading = false;
      }
    }
  }, {
    key: 'destroy',
    value: function destroy() {
      this._baseURL = '';
      this._list = {};
      this._ts = {};
      this.version = 0;
      this.sequence = -1;
      this.targetduration = 0;
      this.duration = 0;
      this.fragLength = 0;
      this._lastget = undefined;
      this.autoclear = false;
    }
  }, {
    key: 'resetSequence',
    value: function resetSequence() {
      this.sequence = -1;
    }
  }, {
    key: 'list',
    get: function get() {
      return this._list;
    }

    /**
     * @param {string} baseURL
     */

  }, {
    key: 'baseURL',
    set: function set(baseURL) {
      if (this.baseURL !== baseURL) {
        this.clear();
        this._baseURL = baseURL;
      }
    }

    /**
     * base cdn url for ts fragments
     * @return {string}
     */
    ,
    get: function get() {
      return this._baseURL;
    }
  }, {
    key: 'avgSegmentDuration',
    get: function get() {
      return this._avgSegmentDuration;
    }
  }]);

  return Playlist;
}();

export default Playlist;