var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import { EVENTS, Mse, Crypto, FetchLoader, logger } from 'xgplayer-helper-utils';
import { RemuxedBufferManager, Tracks, Buffer as XgBuffer, Playlist } from 'xgplayer-helper-models';
import { CompatHls as Compatibility } from 'xgplayer-helper-codec';
import { Mp4Remuxer, M3U8Parser, TsDemuxer } from 'xgplayer-helper-transmuxers';

var LOADER_EVENTS = EVENTS.LOADER_EVENTS;
var REMUX_EVENTS = EVENTS.REMUX_EVENTS;
var DEMUX_EVENTS = EVENTS.DEMUX_EVENTS;
var HLS_EVENTS = EVENTS.HLS_EVENTS;
var CRYTO_EVENTS = EVENTS.CRYTO_EVENTS;
var MSE_EVENTS = EVENTS.MSE_EVENTS;

var HLS_ERROR = 'HLS_ERROR';

var HlsVodController = function () {
  function HlsVodController(configs) {
    _classCallCheck(this, HlsVodController);

    this.TAG = 'HlsVodController';
    this.configs = Object.assign({}, configs);
    this.url = '';
    this.sequence = 0;
    this._playlist = null;
    this.retrytimes = this.configs.retrytimes || 3;
    this.container = this.configs.container;
    this.preloadTime = this.configs.preloadTime || 5;
    this.mse = this.configs.mse;
    this._lastSeekTime = 0;

    this.m3u8Text = null;
  }

  _createClass(HlsVodController, [{
    key: 'init',
    value: function init() {
      // 初始化Buffer （M3U8/TS/Playlist);
      this._context.registry('M3U8_BUFFER', XgBuffer);
      this._tsBuffer = this._context.registry('TS_BUFFER', XgBuffer)();
      this._tracks = this._context.registry('TRACKS', Tracks)();

      this._playlist = this._context.registry('PLAYLIST', Playlist)({ autoclear: false });
      this._presource = this._context.registry('PRE_SOURCE_BUFFER', RemuxedBufferManager)();

      this._compat = this._context.registry('COMPATIBILITY', Compatibility)();

      // 初始化M3U8Loader;
      this._context.registry('M3U8_LOADER', FetchLoader)({ buffer: 'M3U8_BUFFER', readtype: 1 });
      this._tsloader = this._context.registry('TS_LOADER', FetchLoader)({ buffer: 'TS_BUFFER', readtype: 3 });

      // 初始化TS Demuxer
      this._demuxer = this._context.registry('TS_DEMUXER', TsDemuxer)({ inputbuffer: 'TS_BUFFER' });

      // 初始化MP4 Remuxer
      this._context.registry('MP4_REMUXER', Mp4Remuxer)(this._player.currentTime);

      // 初始化MSE
      if (!this.mse) {
        this.mse = new Mse({ container: this.container, preloadTime: this.preloadTime }, this._context);
        this.mse.init();
      }
      this.initEvents();
    }
  }, {
    key: 'initEvents',
    value: function initEvents() {
      this._onLoaderCompete = this._onLoaderCompete.bind(this);
      this._onLoadError = this._onLoadError.bind(this);
      this._onInitSegment = this._onInitSegment.bind(this);
      this._handleSEIParsed = this._handleSEIParsed.bind(this);
      this._onMediaSegment = this._onMediaSegment.bind(this);
      this._onMetadataParsed = this._onMetadataParsed.bind(this);
      this._onDemuxComplete = this._onDemuxComplete.bind(this);
      this._onDemuxError = this._onDemuxError.bind(this);
      this._onRemuxError = this._onRemuxError.bind(this);
      this._handleMseError = this._handleMseError.bind(this);
      this._onUpdateEnd = this._onUpdateEnd.bind(this);
      this._onTimeUpdate = this._onTimeUpdate.bind(this);
      this._onWaiting = this._onWaiting.bind(this);

      this.on(LOADER_EVENTS.LOADER_COMPLETE, this._onLoaderCompete);

      this.on(LOADER_EVENTS.LOADER_ERROR, this._onLoadError);

      this.on(REMUX_EVENTS.INIT_SEGMENT, this._onInitSegment);

      this.on(DEMUX_EVENTS.SEI_PARSED, this._handleSEIParsed);

      this.on(REMUX_EVENTS.MEDIA_SEGMENT, this._onMediaSegment);

      this.on(DEMUX_EVENTS.METADATA_PARSED, this._onMetadataParsed);

      this.on(DEMUX_EVENTS.DEMUX_COMPLETE, this._onDemuxComplete);

      this.on(DEMUX_EVENTS.DEMUX_ERROR, this._onDemuxError);

      this.on(REMUX_EVENTS.REMUX_ERROR, this._onRemuxError);
      this.on(MSE_EVENTS.MSE_ERROR, this._handleMseError);
      this.on(MSE_EVENTS.SOURCE_UPDATE_END, this._onUpdateEnd);

      this.on('TIME_UPDATE', this._onTimeUpdate);

      this.on(MSE_EVENTS.SOURCE_UPDATE_END, this._onTimeUpdate);

      this.on('WAITING', this._onWaiting);
    }
  }, {
    key: '_onError',
    value: function _onError(type, mod, err, fatal) {
      var error = {
        errorType: type,
        errorDetails: '[' + mod + ']: ' + (err ? err.message : ''),
        errorFatal: fatal
      };
      this._player && this._player.emit(HLS_ERROR, error);
    }
  }, {
    key: '_onLoadError',
    value: function _onLoadError(mod, error) {
      this._player.emit('error', {
        errorType: 'network',
        ex: '[' + mod + ']: ' + error.message,
        errd: {}
      });
      this._onError(LOADER_EVENTS.LOADER_ERROR, mod, error, true);
      // this.emit(HLS_EVENTS.RETRY_TIME_EXCEEDED)
    }
  }, {
    key: '_onDemuxError',
    value: function _onDemuxError(mod, error, fatal) {
      if (fatal === undefined) {
        fatal = true;
      }
      this._player.emit('error', {
        errorType: 'parse',
        ex: '[' + mod + ']: ' + error.message,
        errd: {}
      });
      this._onError(LOADER_EVENTS.LOADER_ERROR, mod, error, fatal);
    }
  }, {
    key: '_onRemuxError',
    value: function _onRemuxError(mod, error, fatal) {
      if (fatal === undefined) {
        fatal = true;
      }
      this._player.emit('error', {
        errorType: 'parse',
        ex: '[' + mod + ']: ' + error.message,
        errd: {}
      });
      this._onError(REMUX_EVENTS.REMUX_ERROR, mod, error, fatal);
    }
  }, {
    key: '_handleMseError',
    value: function _handleMseError(tag, err, fatal) {
      if (fatal === undefined) {
        fatal = false;
      }
      this._player.emit('error', {
        errorType: 'format',
        ex: '[' + tag + ']: ' + err.message,
        errd: {}
      });
      this._onError(MSE_EVENTS.MSE_ERROR, tag, err, fatal);
    }
  }, {
    key: '_onWaiting',
    value: function _onWaiting(container) {
      var end = true;

      logger.log(this.TAG, 'waiting.......', this._player.video.currentTime);
      this._seekToBufferStart();
      var playList = Object.keys(this._playlist.list);
      var playListLen = playList.length;
      if (!playListLen) {
        return;
      }

      for (var i = 0; i < playListLen; i++) {
        if (this.container.currentTime * 1000 < parseInt(playList[i])) {
          end = false;
        }
      }
      if (end) {
        var ts = this._playlist.getTs(this.container.currentTime * 1000);
        if (!ts) {
          this._player.emit('ended');
          this.mse.endOfStream();
        } else {
          if (ts.downloaded) {
            this._player.emit('ended');
            this.mse.endOfStream();
          }
        }
      }
    }
  }, {
    key: '_seekToBufferStart',
    value: function _seekToBufferStart() {
      var video = this._player.video;
      var buffered = video.buffered;
      var range = [0, 0];
      var currentTime = video.currentTime;
      if (buffered) {
        for (var i = 0, len = buffered.length; i < len; i++) {
          range[0] = buffered.start(i);
          range[1] = buffered.end(i);
          if (range[0] <= currentTime && currentTime <= range[1]) {
            return;
          }
        }
      }

      var bufferStart = range[0];

      if (currentTime === 0 && currentTime < bufferStart && Math.abs(currentTime - bufferStart) < 5) {
        video.currentTime = bufferStart;
      }
    }
  }, {
    key: '_checkEnd',
    value: function _checkEnd() {
      var video = this._player.video;
      var buffered = video.buffered;
      var len = buffered.length;
      if (!len) return;
      var end = buffered.end(len - 1);
      if (Math.abs(end - video.duration) < 1) {
        this.mse.endOfStream();
      }
    }
  }, {
    key: '_onUpdateEnd',
    value: function _onUpdateEnd() {
      this._checkEnd();
      this._seekToBufferStart();
      this._preload(this._player.currentTime);
    }
  }, {
    key: '_onTimeUpdate',
    value: function _onTimeUpdate() {
      this._seekToBufferStart();
      // this.cleanBuffer()
      this._preload(this._player.currentTime);
    }
  }, {
    key: '_onDemuxComplete',
    value: function _onDemuxComplete() {
      this.emit(REMUX_EVENTS.REMUX_MEDIA, 'ts');
    }
  }, {
    key: '_handleSEIParsed',
    value: function _handleSEIParsed(sei) {
      this._player.emit('SEI_PARSED', sei);
    }
  }, {
    key: '_onMetadataParsed',
    value: function _onMetadataParsed(type) {
      try {
        var m = this._tracks[type + 'Track'].meta;
        logger.warn(this.TAG, 'meta detect or changed , ', type, Object.assign({}, m));
      } catch (e) {}
      var duration = parseInt(this._playlist.duration);
      if (type === 'video') {
        this._context.mediaInfo.hasVideo = true;
        this._tracks.videoTrack.meta.duration = duration;
      } else if (type === 'audio') {
        this._context.mediaInfo.hasAudio = true;
        this._tracks.audioTrack.meta.duration = duration;
      }
      this.emit(REMUX_EVENTS.REMUX_METADATA, type);
    }
  }, {
    key: '_onMediaSegment',
    value: function _onMediaSegment() {
      if (Object.keys(this.mse.sourceBuffers).length < 1) {
        this.mse.addSourceBuffers();
      }

      this.mse.doAppend();
    }
  }, {
    key: '_onInitSegment',
    value: function _onInitSegment() {
      this.mse.addSourceBuffers();
    }
  }, {
    key: '_onLoaderCompete',
    value: function _onLoaderCompete(buffer) {
      if (buffer.TAG === 'M3U8_BUFFER') {
        this.m3u8Text = buffer.shift();
        try {
          var mdata = M3U8Parser.parse(this.m3u8Text, this.url);
          this._playlist.pushM3U8(mdata);
        } catch (error) {
          this._onError('M3U8_PARSER_ERROR', 'PLAYLIST', error, true);
        }
        if (this._playlist.encrypt && this._playlist.encrypt.uri && !this._playlist.encrypt.key) {
          this._context.registry('DECRYPT_BUFFER', XgBuffer)();
          this._context.registry('KEY_BUFFER', XgBuffer)();
          this._tsloader.buffer = 'DECRYPT_BUFFER';
          this._keyLoader = this._context.registry('KEY_LOADER', FetchLoader)({ buffer: 'KEY_BUFFER', readtype: 3 });
          this.emitTo('KEY_LOADER', LOADER_EVENTS.LADER_START, this._playlist.encrypt.uri);
        } else {
          if (!this.preloadTime) {
            if (this._playlist.targetduration) {
              this.preloadTime = this._playlist.targetduration;
              this.mse.preloadTime = this._playlist.targetduration;
            } else {
              this.preloadTime = 5;
              this.mse.preloadTime = 5;
            }
          }
          // 起播分片 < 0.5s的过滤掉
          var frag = this._playlist.getTs((this._player.currentTime + 0.5) * 1000);
          if (frag) {
            this._logDownSegment(frag);
            this._playlist.downloading(frag.url, true);
            this.emitTo('TS_LOADER', LOADER_EVENTS.LADER_START, frag.url);
          } else {
            if (this.retrytimes > 0) {
              this.retrytimes--;
              this.emitTo('M3U8_LOADER', LOADER_EVENTS.LADER_START, this.url);
            }
          }
        }
      } else if (buffer.TAG === 'TS_BUFFER') {
        this._playlist.downloaded(this._tsloader.url, true);
        this._demuxer.demux(Object.assign({ url: this._tsloader.url }, this._playlist._ts[this._tsloader.url]), true);
        this._preload(this.mse.container.currentTime);
      } else if (buffer.TAG === 'DECRYPT_BUFFER') {
        this.retrytimes = this.configs.retrytimes || 3;
        this._playlist.downloaded(this._tsloader.url, true);
        this.emitTo('CRYPTO', CRYTO_EVENTS.START_DECRYPT, Object.assign({ url: this._tsloader.url }, this._playlist._ts[this._tsloader.url]));
      } else if (buffer.TAG === 'KEY_BUFFER') {
        this.retrytimes = this.configs.retrytimes || 3;
        this._playlist.encrypt.key = buffer.shift();
        this._crypto = this._context.registry('CRYPTO', Crypto)({
          key: this._playlist.encrypt.key,
          iv: this._playlist.encrypt.ivb,
          method: this._playlist.encrypt.method,
          inputbuffer: 'DECRYPT_BUFFER',
          outputbuffer: 'TS_BUFFER'
        });

        this._crypto.on(CRYTO_EVENTS.DECRYPTED, this._onDcripted.bind(this));

        var _frag = this._playlist.getTs();
        if (_frag) {
          this._playlist.downloading(_frag.url, true);
          this.emitTo('TS_LOADER', LOADER_EVENTS.LADER_START, _frag.url);
        } else {
          if (this.retrytimes > 0) {
            this.retrytimes--;
            this.emitTo('M3U8_LOADER', LOADER_EVENTS.LADER_START, this.url);
          }
        }
      }
    }
  }, {
    key: '_onDcripted',
    value: function _onDcripted() {
      this.emit(DEMUX_EVENTS.DEMUX_START);
    }
  }, {
    key: 'seek',
    value: function seek(time) {
      var video = this._player.video;


      for (var i = 0; i < video.buffered.length; i++) {
        if (time >= video.buffered.start(i) && time < video.buffered.end(i)) {
          // this._playlist.clearDownloaded();
          return;
        }
      }

      logger.warn(this.TAG, 'seek: ', time, 'tsloading:', this._tsloader.loading);

      // 连续seek,匹配的是同一个分片且正在加载,tsloader不销毁
      var frag = this._playlist.getTs((time + 0.5) * 1000);
      if (frag && this._tsloader.loading && this._tsloader.url === frag.url) return;

      this._lastSeekTime = time;
      this._tsloader.destroy();
      this._tsloader = this._context.registry('TS_LOADER', FetchLoader)({ buffer: 'TS_BUFFER', readtype: 3 });
      if (this._presource.sources.video) {
        this._presource.sources.video.data = [];
      }
      if (this._presource.sources.audio) {
        this._presource.sources.audio.data = [];
      }
      if (this._tracks.audioTrack) {
        this._tracks.audioTrack.samples = [];
      }
      if (this._tracks.videoTrack) {
        this._tracks.videoTrack.samples = [];
      }

      if (this._compat) {
        this._compat.reset();
      }

      if (this._tsBuffer) {
        this._tsBuffer.array = [];
        this._tsBuffer.length = 0;
        this._tsBuffer.offset = 0;
      }
      this._playlist.clearDownloaded();
      this._context.seek(time);
      this._preload(time);
    }
  }, {
    key: 'replay',
    value: function replay() {
      this._compat.reset();
      this._playlist.clearDownloaded();
    }
  }, {
    key: 'load',
    value: function load(url) {
      this.url = url;
      this.emitTo('M3U8_LOADER', LOADER_EVENTS.LADER_START, url, null, 0);
    }
  }, {
    key: '_preload',
    value: function _preload(time) {
      time = Math.floor(time);
      if (this._tsloader.loading) {
        return;
      }
      logger.log(this.TAG, 'preload: time=', time);
      var video = this.mse.container;
      // Get current time range
      var currentbufferend = -1;
      if (!time && video.buffered.length) {
        time = video.buffered.start(0);
      }

      for (var i = 0; i < video.buffered.length; i++) {
        if (time >= Math.floor(video.buffered.start(i)) && time <= Math.ceil(video.buffered.end(i))) {
          currentbufferend = video.buffered.end(i);
        }
      }

      if (currentbufferend < 0) {
        var frag = this._playlist.getTs((time + 0.5) * 1000); // FIXME: Last frame buffer shortens duration
        if (frag && frag.downloaded) {
          frag = this._playlist.getTs(frag.time + frag.duration);
        }
        if (frag && !frag.downloading && !frag.downloaded) {
          this._logDownSegment(frag);
          this._playlist.downloading(frag.url, true);
          this.emitTo('TS_LOADER', LOADER_EVENTS.LADER_START, frag.url);
        }
      } else if (currentbufferend < time + this.preloadTime) {
        var _frag2 = this._playlist.getLastDownloadedTs() || this._playlist.getTs(currentbufferend * 1000);

        if (!_frag2) {
          return;
        }

        // let fragend = frag ? (frag.time + frag.duration) / 1000 : 0;

        var curTime = _frag2.time + _frag2.duration;
        var curFragTime = _frag2.time;

        if (_frag2.downloaded) {
          var loopMax = 1000;
          while (loopMax-- > 0) {
            curTime += 10;
            _frag2 = this._playlist.getTs(curTime);
            if (!_frag2 || _frag2.time > curFragTime) {
              break;
            }
          }
        }

        if (_frag2 && !_frag2.downloading && !_frag2.downloaded) {
          this._logDownSegment(_frag2);
          this._playlist.downloading(_frag2.url, true);
          this.emitTo('TS_LOADER', LOADER_EVENTS.LADER_START, _frag2.url);
        }
      }
    }
  }, {
    key: 'cleanBuffer',
    value: function cleanBuffer() {
      var _this = this;

      var time = this._player.currentTime;

      var video = this._player.video;
      var buffered = video.buffered;

      if (!buffered || !buffered.length) {
        return;
      }

      var range = [0, 0];
      var currentTime = video.currentTime;
      if (buffered) {
        for (var i = 0, len = buffered.length; i < len; i++) {
          range[0] = buffered.start(i);
          range[1] = buffered.end(i);
          if (range[0] <= currentTime && currentTime <= range[1]) {
            break;
          }
        }
      }

      var bufferStart = range[0];
      var bufferEnd = range[1];

      if (time - bufferStart > 10 || buffered.length > 1) {
        this.mse.remove(Math.max(Math.min(time - 10, bufferEnd - 10), 0.1), 0);

        this.bufferClearTimer = setTimeout(function () {
          _this.bufferClearTimer = null;
        }, 5000);
      }
    }
  }, {
    key: 'destory',
    value: function destory() {
      this.configs = {};
      this.url = '';
      this.sequence = 0;
      this._playlist = null;
      this.retrytimes = 3;
      this.container = undefined;
      this.preloadTime = 5;
      this._lastSeekTime = 0;
      this.m3u8Text = null;
      this.mse = null;

      this.off(LOADER_EVENTS.LOADER_COMPLETE, this._onLoaderCompete);

      this.off(LOADER_EVENTS.LOADER_ERROR, this._onLoadError);

      this.off(REMUX_EVENTS.INIT_SEGMENT, this._onInitSegment);

      this.off(REMUX_EVENTS.MEDIA_SEGMENT, this._onMediaSegment);

      this.off(DEMUX_EVENTS.METADATA_PARSED, this._onMetadataParsed);

      this.off(DEMUX_EVENTS.DEMUX_COMPLETE, this._onDemuxComplete);

      this.off('TIME_UPDATE', this._onTimeUpdate);

      this.off('WAITING', this._onWaiting);
    }
  }, {
    key: '_logDownSegment',
    value: function _logDownSegment(frag) {
      if (!frag) return;
      logger.groupEnd();
      logger.group(this.TAG, 'load ' + frag.id + ': [' + frag.time / 1000 + ' , ' + (frag.time + frag.duration) / 1000 + '], downloading: ' + frag.downloading + ' , donwloaded: ' + frag.downloaded);
    }
  }]);

  return HlsVodController;
}();

export default HlsVodController;