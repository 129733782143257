var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import { logger } from 'xgplayer-helper-utils';
import EventEmitter from 'eventemitter3';
import Buffer from './buffer';
import Fmp4 from './fmp4';

/**
 * @typedef { import('xgplayer-helper-models').VideoTrack } VideoTrack
 */
/**
 * @typedef { import('xgplayer-helper-models').AudioTrack } AudioTrack
 */

var Mp4Remuxer = function (_EventEmitter) {
  _inherits(Mp4Remuxer, _EventEmitter);

  function Mp4Remuxer(_ref) {
    var videoMeta = _ref.videoMeta,
        audioMeta = _ref.audioMeta,
        curTime = _ref.curTime;

    _classCallCheck(this, Mp4Remuxer);

    var _this = _possibleConstructorReturn(this, (Mp4Remuxer.__proto__ || Object.getPrototypeOf(Mp4Remuxer)).call(this));

    _this.TAG = 'Fmp4Remuxer';
    _this._dtsBase = curTime * 1000;

    _this._videoMeta = videoMeta;
    _this._audioMeta = audioMeta;

    _this._audioDtsBase = null;
    _this._videoDtsBase = null;
    _this._isDtsBaseInited = false;

    _this.isFirstVideo = true;
    _this.isFirstAudio = true;

    _this.videoAllDuration = 0;
    _this.audioAllDuration = 0;

    _this.audioRemuxed = 0;
    _this.videoRemuxed = 0;
    _this.mp4Durations = {
      keys: []
    };
    return _this;
  }

  _createClass(Mp4Remuxer, [{
    key: 'destroy',
    value: function destroy() {
      this._dtsBase = -1;
      this._isDtsBaseInited = false;
      this.mp4Durations = {
        keys: []
      };

      this.removeAllListeners();
    }

    /**
     * @param {AudioTrack} audioTrack
     * @param {VideoTrack} videoTrack
     */

  }, {
    key: 'remux',
    value: function remux(audioTrack, videoTrack) {
      !this._isDtsBaseInited && this.calcDtsBase(audioTrack, videoTrack);
      this.remuxVideo(videoTrack);
      this.remuxAudio(audioTrack);

      logger.groupEnd();
    }
  }, {
    key: 'resetDtsBase',
    value: function resetDtsBase() {
      this._dtsBase = 0;
    }
  }, {
    key: 'seek',
    value: function seek(time) {
      logger.log(this.TAG, 'set dtsBase for seek(),time=', time);
      if (!this._isDtsBaseInited) {
        this._dtsBase = time * 1000;
      } else {
        this._isDtsBaseInited = false;
        this._dtsBase = time * 1000;
      }

      this._audioDtsBase = this._videoDtsBase = null;
    }

    /**
     * @param {'video' | 'audio' } type
     * @param {*} meta
     * @return {Buffer}
     */

  }, {
    key: 'remuxInitSegment',
    value: function remuxInitSegment(type, meta) {
      logger.log(this.TAG, 'remuxInitSegment: type=', type);
      var initSegment = new Buffer();
      var ftyp = meta.streamType === 0x24 ? Fmp4.ftypHEVC() : Fmp4.ftyp();
      var moov = Fmp4.moov({ type: type, meta: meta });

      initSegment.write(ftyp, moov);
      return initSegment;
    }

    /**
     * @param {AudioTrack} audioTrack
     * @param {VideoTrack} videoTrack
     */

  }, {
    key: 'calcDtsBase',
    value: function calcDtsBase(audioTrack, videoTrack) {
      if (!audioTrack && videoTrack.samples.length) {
        var firstSample = videoTrack.samples[0];
        var _start = void 0;
        if (firstSample.options && firstSample.options.start) {
          _start = firstSample.options.start;
        }
        this._videoDtsBase = firstSample.dts - (_start || this._dtsBase);
        this._isDtsBaseInited = true;
        return;
      }

      if ((!audioTrack || !audioTrack.samples.length) && (!videoTrack || !videoTrack.samples.length)) {
        return;
      }

      var audioBase = null;
      var videoBase = null;
      var start = null;
      if (audioTrack && audioTrack.samples && audioTrack.samples.length) {
        var _firstSample = audioTrack.samples[0];
        audioBase = _firstSample.dts;
        if (_firstSample.options && _firstSample.options.start) {
          start = _firstSample.options.start;
        }
      }
      if (videoTrack && videoTrack.samples && videoTrack.samples.length) {
        var _firstSample2 = videoTrack.samples[0];
        videoBase = _firstSample2.dts;
        if (_firstSample2.options && _firstSample2.options.start) {
          start = _firstSample2.options.start;
        }
      }

      var delta = audioBase - videoBase;
      // 临时兼容逻辑， hls 考虑上av之间的差值
      if (!!audioBase && delta < 0 && start !== null) {
        videoTrack.samples.forEach(function (x) {
          x.dts -= delta;
          if (x.pts) {
            x.pts -= delta;
          }
        });
      }

      this._videoDtsBase = (videoBase || audioBase) - (start || this._dtsBase);
      this._audioDtsBase = (audioBase || videoBase) - (start || this._dtsBase);
      this._dtsBase = Math.min(videoBase, audioBase);
      this._isDtsBaseInited = true;

      logger.log(this.TAG, 'calcDtsBase');
      logger.log(this.TAG, 'video first dts: ' + videoBase + ' , start:' + start + ' , _videoDtsBase:' + this._videoDtsBase + ' , _dtsBase:' + this._dtsBase);
      logger.log(this.TAG, 'audio first dts: ' + audioBase + ' , start:' + start + ' , _audioDtsBase:' + this._audioDtsBase + ', _dtsBase:' + this._dtsBase);
    }

    /**
     * @param {VideoTrack}videoTrack
     * @return {*}
     * @private
     */

  }, {
    key: 'remuxVideo',
    value: function remuxVideo(videoTrack) {
      var _this2 = this;

      var track = videoTrack || {};

      if (!videoTrack || !videoTrack.samples || !videoTrack.samples.length) {
        return;
      }

      var samples = track.samples,
          meta = track.meta;


      if (!meta) return;

      var firstDts = -1;

      var initSegment = null;
      var mp4Samples = [];
      var mdatBox = {
        samples: []
      };

      var maxLoop = 10000;
      while (samples.length && maxLoop-- > 0) {
        var avcSample = samples.shift();

        var isKeyframe = avcSample.isKeyframe,
            options = avcSample.options;

        if (!this.isFirstVideo && options && options.meta) {
          initSegment = this.remuxInitSegment('video', options.meta);
          options.meta = null;
          samples.unshift(avcSample);
          if (!options.isContinue) {
            this._videoDtsBase = 0;
          }
          break;
        }
        var dts = Math.max(0, avcSample.dts - this.videoDtsBase);
        if (firstDts === -1) {
          firstDts = dts;
        }

        var cts = void 0;
        var pts = void 0;
        if (avcSample.pts !== undefined) {
          pts = avcSample.pts - this._dtsBase;
          cts = pts - dts;
        }
        if (avcSample.cts !== undefined) {
          pts = avcSample.cts + dts;
          cts = avcSample.cts;
        }

        var mdatSample = {
          buffer: [],
          size: 0
        };

        var sampleDuration = 0;
        if (avcSample.duration) {
          sampleDuration = avcSample.duration;
        } else if (samples.length >= 1) {
          var nextDts = samples[0].dts - this.videoDtsBase;
          sampleDuration = nextDts - dts;
        } else {
          if (mp4Samples.length >= 1) {
            // lastest sample, use second last duration
            sampleDuration = mp4Samples[mp4Samples.length - 1].duration;
          } else {
            // the only one sample, use reference duration
            sampleDuration = this._videoMeta.refSampleDuration;
          }
        }
        this.videoAllDuration += sampleDuration;
        if (logger.long) {
          logger.log(this.TAG, 'video dts ' + dts, 'pts ' + pts, 'cts: ' + cts, isKeyframe, 'originDts ' + avcSample.originDts, 'duration ' + sampleDuration);
        }
        if (sampleDuration >= 0) {
          mdatBox.samples.push(mdatSample);
          mdatSample.buffer.push(avcSample.data);
          mdatSample.size += avcSample.data.byteLength;
          //  manage same pts sample
          // if (this.mp4Durations[pts]) {
          //   pts += this.mp4Durations[pts]
          //   cts = pts - dts
          // }
          mp4Samples.push({
            dts: dts,
            cts: cts,
            pts: pts,
            data: avcSample.data,
            size: avcSample.data.byteLength,
            isKeyframe: isKeyframe,
            duration: sampleDuration,
            flags: {
              isLeading: 0,
              dependsOn: isKeyframe ? 2 : 1,
              isDependedOn: isKeyframe ? 1 : 0,
              hasRedundancy: 0,
              isNonSync: isKeyframe ? 0 : 1
            },
            originDts: dts,
            type: 'video'
          });
          this.mp4Durations[pts] = sampleDuration;
          this.mp4Durations.keys.push(pts);
        }

        if (isKeyframe) {
          this.emit(Mp4Remuxer.EVENTS.RANDOM_ACCESS_POINT, pts);
        }
      }
      // delete too much data
      if (this.mp4Durations.keys.length > 1e4) {
        var tmp = this.mp4Durations;
        this.mp4Durations = {};
        this.mp4Durations.keys = tmp.keys.slice(-100);
        this.mp4Durations.keys.forEach(function (key) {
          _this2.mp4Durations[key] = tmp[key];
        });
      }
      if (mp4Samples.length) {
        logger.log(this.TAG, 'remux to mp4 video:', [firstDts / 1000, mp4Samples[mp4Samples.length - 1].dts / 1000]);
      }

      var moofMdat = new Buffer();
      if (mp4Samples.length && track.meta) {
        var moof = Fmp4.moof({
          id: track.meta.id,
          time: firstDts,
          samples: mp4Samples
        });
        var mdat = Fmp4.mdat(mdatBox);
        moofMdat.write(moof, mdat);

        this.segmentRemuxed('video', moofMdat, mp4Samples[mp4Samples.length - 1].pts - mp4Samples[0].pts);
      }

      if (initSegment) {
        this.segmentRemuxed('video', initSegment);

        if (samples.length) {
          // second part of stream change
          track.samples = samples;
          return this.remuxVideo(track);
        }
      }

      this.isFirstVideo = false;
      this.emit(Mp4Remuxer.EVENTS.TRACK_REMUXED, 'video', moofMdat);

      track.samples = [];
      track.length = 0;
    }

    /**
     *
     * @param {AudioTrack} track
     * @return {*}
     * @private
     */

  }, {
    key: 'remuxAudio',
    value: function remuxAudio(track) {
      var _ref2 = track || {},
          samples = _ref2.samples;

      var firstDts = -1;
      var mp4Samples = [];

      var initSegment = null;
      var mdatBox = {
        samples: []
      };
      if (!samples || !samples.length) {
        return;
      }

      var maxLoop = 10000;
      var isFirstDtsInited = false;
      while (samples.length && maxLoop-- > 0) {
        var sample = samples.shift();
        var data = sample.data,
            options = sample.options;

        if (!this.isFirstAudio && options && options.meta) {
          initSegment = this.remuxInitSegment('audio', options.meta);
          options.meta = null;
          samples.unshift(sample);
          if (!options.isContinue) {
            this._audioDtsBase = 0;
          }
          break;
        }

        var dts = Math.max(0, sample.dts - this.audioDtsBase);
        var originDts = sample.originDts;
        if (!isFirstDtsInited) {
          firstDts = dts;
          isFirstDtsInited = true;
        }

        var sampleDuration = 0;
        if (sample.duration) {
          sampleDuration = sample.duration;
        } else if (this._audioMeta.refSampleDurationFixed) {
          sampleDuration = this._audioMeta.refSampleDurationFixed;
        } else if (samples.length >= 1) {
          var nextDts = samples[0].dts - this.audioDtsBase;
          sampleDuration = nextDts - dts;
        } else {
          if (mp4Samples.length >= 1) {
            // use second last sample duration
            sampleDuration = mp4Samples[mp4Samples.length - 1].duration;
          } else {
            // the only one sample, use reference sample duration
            sampleDuration = this._audioMeta.refSampleDuration;
          }
        }

        if (logger.long) {
          // logger.log(this.TAG, `audio dts ${dts}`, `pts ${dts}`, `originDts ${originDts}`, `duration ${sampleDuration}`)
        }
        this.audioAllDuration += sampleDuration;
        var mp4Sample = {
          dts: dts,
          pts: dts,
          cts: 0,
          size: data.byteLength,
          duration: sample.duration ? sample.duration : sampleDuration,
          flags: {
            isLeading: 0,
            dependsOn: 1,
            isDependedOn: 0,
            hasRedundancy: 0,
            isNonSync: 0
          },
          isKeyframe: true,
          originDts: originDts,
          type: 'audio'
        };

        var mdatSample = {
          buffer: [],
          size: 0
        };

        if (sampleDuration >= 0) {
          mdatSample.buffer.push(data);
          mdatSample.size += data.byteLength;

          mdatBox.samples.push(mdatSample);
          mp4Samples.push(mp4Sample);
        }
      }

      var moofMdat = new Buffer();

      if (mp4Samples.length && track.meta) {
        logger.log(this.TAG, 'remux to mp4 audio:', [firstDts / 1000, mp4Samples[mp4Samples.length - 1].dts / 1000]);
        var moof = Fmp4.moof({
          id: track.meta.id,
          time: firstDts,
          samples: mp4Samples
        });
        var mdat = Fmp4.mdat(mdatBox);
        moofMdat.write(moof, mdat);

        this.segmentRemuxed('audio', moofMdat, mp4Samples[mp4Samples.length - 1].dts - mp4Samples[0].dts);
      }

      if (initSegment) {
        this.segmentRemuxed('audio', initSegment);
        if (samples.length) {
          // second part of stream change
          track.samples = samples;
          return this.remuxAudio(track);
        }
      }

      this.isFirstAudio = false;
      this.emit(Mp4Remuxer.EVENTS.TRACK_REMUXED, 'audio', moofMdat);

      track.samples = [];
      track.length = 0;
    }
  }, {
    key: 'segmentRemuxed',
    value: function segmentRemuxed(type, buffer, bufferDuration) {
      this.emit(Mp4Remuxer.EVENTS.MEDIA_SEGMENT, type, buffer, bufferDuration);
    }
  }, {
    key: 'videoDtsBase',
    get: function get() {
      if (this._videoDtsBase !== null) {
        return this._videoDtsBase;
      }
      return this._dtsBase;
    },
    set: function set(value) {
      this._videoDtsBase = value;
    }
  }, {
    key: 'audioDtsBase',
    get: function get() {
      if (this._audioDtsBase !== null) {
        return this._audioDtsBase;
      }
      return this._dtsBase;
    }
  }], [{
    key: 'EVENTS',
    get: function get() {
      return {
        MEDIA_SEGMENT: 'MEDIA_SEGMENT',
        INIT_SEGMENT: 'INIT_SEGMENT',
        RANDOM_ACCESS_POINT: 'RANDOM_ACCESS_POINT',
        TRACK_REMUXED: 'TRACK_REMUXED'
      };
    }
  }]);

  return Mp4Remuxer;
}(EventEmitter);

export default Mp4Remuxer;